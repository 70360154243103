import { Form, Grid, Input, message, Select } from 'antd';
import { Modal } from 'components';
import { useCreateCategory } from 'features/master-management/use-cases/create-category';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const { useBreakpoint } = Grid;

export const AddCategoryUserModal = ({
  onClose,
  editData,
  setEditData,
  refetch,
}: {
  onClose: () => void;
  editData: any;
  setEditData: any;
  refetch: any;
}) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { isLoading, mutate } = useCreateCategory();
  const { data: Frequency } = useDropDownFlag('Frequency');
  const { id } = useParams();
  useEffect(() => {
    // Set initial form values when editData changes
    if (editData) {
      form.setFieldsValue({
        categoryName: editData.categoryName, // Assuming `editData.text` contains the category name
      });
    } else {
      form.resetFields(); // Clear form if there's no editData
    }
  }, [editData, form]);

  const handleUpdate = (formData: {
    categoryName: string;
    frequencyId?: string;
  }) => {
    let formdata: any;
    if (editData?.id) {
      formdata = {
        categoryName: formData.categoryName,
        companyId: id,
        ...(editData?.id && { id: editData?.id }), //
      };
    } else {
      formdata = {
        categoryName: formData.categoryName,
        frequencyId: formData.frequencyId ? formData.frequencyId : '', // Provide a default if undefined
        companyId: id,
      };
    }

    mutate(formdata, {
      onSuccess: () => {
        onClose(); // Close the modal on success
        refetch(); // Refresh data (ensure `refetch` is defined and imported)
        if (editData?.id) {
          message.success('Category updated successfully');
        } else {
          message.success('Category created successfully');
        }
      },
    });
  };

  const handleClose = () => {
    form.resetFields(); // Reset all form fields
    setEditData(null); // Clear edit data
    onClose(); // Close modal
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={editData ? 'Edit Category' : 'Create Category'}
        onCancel={handleClose}
        getContainer={false}
        onOk={form.submit}
        okText={editData ? 'Edit Category' : 'Create Category'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item
            name='categoryName'
            label='Category Name'
            rules={[
              {
                required: true,
                message: 'Category name is required.',
              },
            ]}
          >
            <Input placeholder='Enter Category Name' />
          </Form.Item>
          {!editData && (
            <Form.Item name={'frequencyId'} label='Frequency'>
              <Select
                options={Frequency?.data?.rc.map((item: any) => ({
                  label: item.text,
                  value: item.value,
                }))}
                placeholder='Please select'
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
