import { Button, Form, Grid, message, Select } from 'antd';
import { Modal } from 'components';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { useClientDocumentApproveReject } from 'features/assigned-clients/use-cases/approve-reject-document';
import { useDropDownFlag } from 'features/assigned-clients/use-cases/drop-down';
import { useMoveDocuments } from 'features/assigned-clients/use-cases/move-documents';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { reminderFormValue } from 'features/new-clients/components/clients-form/type';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const { useBreakpoint } = Grid;

export const ApproveLocationDocumentChangeViewModal = ({
  onClose,
  item,
  reFetchPendingDocument,
  documentsRefetch,
  getDocumentsCount,
  handleClick,
}: {
  onClose: () => void;
  item: any;
  reFetchPendingDocument: any;
  documentsRefetch: any;
  getDocumentsCount: any;
  handleClick: any;
}) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [changeLoctionItem, setChangeLoctionItem] = useState(false);
  const [subCategoryValue, setSubCategoryValue] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const [subClassValue, setSubClassValue] = useState<any>('');
  const { id } = useParams();
  const [form] = Form.useForm<reminderFormValue>();
  const { data: categories } = useGetCategoryList({ retry: false });
  const { data: subCategory } = useDropDownFlag(
    'SubCategory',
    subCategoryValue,
  );
  const { mutate: approveRejectDocuemnt, isLoading } =
    useClientDocumentApproveReject(item?.companyId);
  const { isLoading: moveIsLoading, mutate } = useMoveDocuments();

  const initialValues = async () => {
    try {
      const apires: any = await AssignedClientsApiService().dropDownByClassFlag(
        {
          Flag: 'Class',
          ParentId: subClassValue,
          ClientId: ids,
          CompanyId: id,
        },
      );
      setSubClassValue(apires);
    } catch (error: any) {
      message.error(error);
    }
  };

  const onChangeApprove = () => {
    const formData = new FormData();
    formData.append('CompanyId', item.companyId);
    formData.append('DocumentId', item.documentId);
    formData.append('ServiceId', item.serviceId);
    formData.append('StatusId', '2'); // Approve Status ID
    formData.append('Reason', '');
    formData.append('Comment', '');
    approveRejectDocuemnt(formData, {
      onSuccess: () => {
        reFetchPendingDocument(); // Call reFetchPendingDocument on success
        documentsRefetch();
        getDocumentsCount();
        handleClick('documents');
        onClose();
      },
    });
  };

  const onChangeLocation = (formValues: any) => {
    const IsAdmin = true;
    const formData = new FormData();
    formData.append('DocumentId', item?.documentId);
    formData.append('IsAdmin', IsAdmin.toString());
    formData.append('StatusId', '2');
    let serviceId = formValues.class; // Default to class
    if (formValues.category && formValues.SubCategory) {
      serviceId = formValues.SubCategory; // If both category and subcategory are selected, use subcategory
    } else if (formValues.category) {
      serviceId = formValues.category; // If only category is selected, use category
    }
    // Override if all three are selected to use `class`
    if (formValues.category && formValues.SubCategory && formValues.class) {
      serviceId = formValues.class; // If all three are selected, prioritize class
    }
    formData.append('ServiceId', serviceId); // Use the resolved serviceId
    mutate(formData, {
      onSuccess: (data) => {
        if (data?.rs === 1) {
          message.success('Document moved successfully');
          reFetchPendingDocument();
          documentsRefetch();
          getDocumentsCount();
          handleClick('documents');
          onClose();
        }
      },
    });
  };

  useEffect(() => {
    if (subClassValue) {
      initialValues();
    }
  }, [subClassValue]);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Change Location & Approved'}
        onCancel={onClose}
        closeIcon={''}
        getContainer={false}
        footer={[
          // Conditionally render Same Location button
          !changeLoctionItem ? (
            <Button
              key='same'
              type='primary'
              loading={isLoading}
              onClick={onChangeApprove}
            >
              Keep it & Approved
            </Button>
          ) : null,
          // Conditionally render Change Location button
          !changeLoctionItem ? (
            <Button
              key='change'
              type='primary'
              onClick={() => setChangeLoctionItem(true)}
            >
              Change Location
            </Button>
          ) : null,
          // Conditionally render Send and Back buttons
          changeLoctionItem ? (
            <Button
              key='send'
              type='primary'
              loading={moveIsLoading}
              onClick={() => form.submit()}
            >
              Change & Approved
            </Button>
          ) : null,
        ]}
        width={screens.lg ? '500px' : '100%'}
      >
        {changeLoctionItem && (
          <Form
            id={formName}
            name={formName}
            form={form}
            layout='vertical'
            onFinish={onChangeLocation} // Triggers onChangeLocation when the form is submitted
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
          >
            <>
              <Form.Item
                label='Select Category'
                name='category'
                rules={[
                  { required: true, message: 'Please select a category' },
                ]}
              >
                <Select
                  options={categories?.rc?.map((item1: any) => ({
                    label: item1.categoryName,
                    value: item1.id,
                  }))}
                  onChange={(value) => setSubCategoryValue(value)}
                  placeholder='Please Select'
                />
              </Form.Item>

              {subCategory?.data?.rc?.length > 0 && (
                <Form.Item
                  name='subCategory'
                  label='Sub Category'
                  rules={[{ required: false }]}
                >
                  <Select
                    options={subCategory?.data.rc?.map((item2: any) => ({
                      label: item2.text,
                      value: item2.value,
                    }))}
                    placeholder='Please Select'
                    onChange={(value) => setSubClassValue(value)}
                  />
                </Form.Item>
              )}

              {subClassValue?.data?.rc?.length > 0 && (
                <Form.Item name={'class'} label={'Class'}>
                  <Select
                    options={subClassValue?.data?.rc?.map((item3: any) => ({
                      label: item3.text,
                      value: item3.value,
                    }))}
                    placeholder='Please Select'
                  />
                </Form.Item>
              )}
            </>
          </Form>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
