import { LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { UserFormField } from 'features/users/components/users-filter-form';
import { useTranslation } from 'react-i18next';

type PasswordFormItemsProps = {
  title?: string;
};

export const PasswordFormItems = ({ title = '' }: PasswordFormItemsProps) => {
  const { t } = useTranslation();

  return (
    <FormSectionsSeparator title={title}>
      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.password.label')}
        name={'Password'}
        rules={[
          {
            required: true,
            whitespace: true,
            min: 8,
          },
          {
            pattern: /(?=.*\d)/,
            message: t('validateMessages.custom.passwordDigit'),
          },
          {
            pattern: /(?=.*[a-z])/,
            message: t('validateMessages.custom.passwordLowercase'),
          },
          {
            pattern: /(?=.*[A-Z])/,
            message: t('validateMessages.custom.passwordUppercase'),
          },
        ]}
      >
        <Input.Password
          placeholder={t('adminUsers.formFields.password.placeholder')}
          prefix={<LockOutlined className='input-prefix-icon' />}
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.confirmPassword.label')}
        name={'confirmPassword'}
        rules={[
          {
            required: true,
            whitespace: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('Password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                t('validateMessages.custom.confirmPassword'),
              );
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={t('adminUsers.formFields.confirmPassword.placeholder')}
          prefix={<LockOutlined className='input-prefix-icon' />}
        />
      </Form.Item>
    </FormSectionsSeparator>
  );
};
