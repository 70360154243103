import { Form, Grid, message, Select, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Loader, Modal, Result404 } from 'components';
import { assignFormFieldValue } from 'features/assigned-clients/components/form-filter/type';
import { useCancelCreateSubscription } from 'features/assigned-clients/use-cases/cancel-subscription';
import { useDropDownFlag } from 'features/assigned-clients/use-cases/drop-down';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
const { useBreakpoint } = Grid;
export const CancelSubscriptionModal = ({
  onClose,
  assignedData,
}: {
  onClose: () => void;
  assignedData: any;
}) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<assignFormFieldValue>();
  const { isLoading, mutate } = useCancelCreateSubscription();
  const {
    data: subscriptiondata,
    isLoading: isLoadingGet,
    isError,
  } = useDropDownFlag('CancelSubscriptionReason', '');
  const handleUpdate = (formvalue: any) => {
    const payload = {
      companyId: assignedData?.companyId,
      statusId: assignedData?.status == 'Active' ? false : true,
      reason: formvalue?.reason,
      comment: formvalue?.comment,
    };
    mutate(payload, {
      onSuccess: () => {
        message.success('Subscription Cancel successfully');
        onClose();
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Cancel Subscription'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Save'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        {isLoadingGet ? (
          <Loader />
        ) : isError ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoadingGet}>
            <Form<assignFormFieldValue>
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              // initialValues={getInitialValues}
              onFinish={handleUpdate}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading}
            >
              <Form.Item
                label='Select Reason'
                name={'reason'}
                rules={[{ required: true, message: 'Please Select reason' }]}
              >
                <Select
                  options={subscriptiondata?.data?.rc?.map((item: any) => ({
                    label: item.text,
                    value: item.text,
                  }))}
                  placeholder='Please Select'
                />
              </Form.Item>
              <Form.Item
                label='Comments'
                name='comment'
                rules={[
                  {
                    required: true,
                    message: 'Please add a comment', // Validation message when empty
                  },
                ]}
              >
                <TextArea placeholder='Add Comments' />
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
