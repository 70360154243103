import { CloudUploadOutlined } from '@ant-design/icons';
import {
  Form,
  Grid,
  Image,
  message,
  Select,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { Modal } from 'components';
import { useUploadImage } from 'features/assigned-clients/use-cases/upload-image';
import { useActivated } from 'features/new-clients/use-cases/cerate-activate';
import { useGetUsersList } from 'features/users';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { convertFileSize, validateFileBeforeUpload } from 'routes/utils';

const { useBreakpoint } = Grid;

export const AddActivateModal = ({
  onClose,
  clientsdata,
}: {
  onClose: () => void;
  clientsdata: any;
}) => {
  const formName = 'add-activate-form';
  const screens = useBreakpoint();
  const { id } = useParams();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [params] = useState({
    PageIndex: 1,
    RecordPerPage: 10,
    IsActive: true,
  });
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [filenameUrl, setFileNameUrl] = useState('');
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false); // Loading state for image upload
  const { isLoading: imageLoading, mutate: uploadImage } = useUploadImage();
  const { isLoading, mutate } = useActivated();
  const { data } = useGetUsersList(params, {
    retry: false,
  });
  const handleImageUpload = (file: UploadFile) => {
    const formData = new FormData();
    formData.append('file', file as any);

    setIsImageUploading(true); // Set uploading state to true
    uploadImage(
      { id: id as string, formData }, // Pass id and file as payload
      {
        onSuccess: (response: any) => {
          setFileNameUrl(response.fileName);
          message.success('File uploaded successfully');
          setIsImageUploading(false); // Reset uploading state
        },
        onError: () => {
          message.error('file upload failed');
          setIsImageUploading(false); // Reset uploading state
        },
      },
    );
  };
  const handleUpdate = (formValue: any) => {
    const formData = new FormData();
    const file: any = fileList[0]?.originFileObj;
    if (file == undefined) {
      return message.error('Please Upload');
    }
    formData.append('ClientID', clientsdata?.rc[0]?.clientId as any);
    formData.append('CompanyId', id as any);
    // Append multiple AdminUserIds in the desired format
    const adminUsers = formValue.AdminUserlt.map((userId: string) => ({
      Id: userId,
    }));
    formData.append('AdminUserlt', JSON.stringify(adminUsers));
    const uploadedFile = fileList[0]?.originFileObj; // Assuming fileList contains the uploaded file
    if (uploadedFile) {
      // Get the filename and filesize from the file object
      const fileSize = uploadedFile.size;
      // Append filename and filesize to FormData
      formData.append('FileName', filenameUrl);
      formData.append('FileSize', convertFileSize(fileSize)); // FileSize is typically a number, so convert it to string
    }

    mutate(formData, {
      onSuccess: (data1: any) => {
        if (data1?.rs == 0) {
          message.error(data1?.rm);
        } else {
          window.history.back();
        }
        onClose();
      },
      onError: () => {
        message.error('Failed to activate user. Please try again.');
      },
    });
  };
  const beforeUpload = (file: any) => {
    const isValid = validateFileBeforeUpload(file);
    if (isValid) {
      handleImageUpload(file); // Call handleImageUpload only if valid
    }

    return isValid; // If false, upload will be prevented
  };
  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      type='button'
    >
      <CloudUploadOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <StyledUserModalWrapper>
      <Modal
        className='docupldmdl mbl_modal_center'
        open
        centered
        title={'Upload Signed Contract'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Save'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
          disabled: imageLoading || isImageUploading,
        }}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={{
            AdminUserlt: [],
          }}
          onFinish={handleUpdate}
          onFinishFailed={() => setValidateTrigger(['onChange'])}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <h3>
            To activate this User account, you need to upload the signed version
            of the contract.
          </h3>

          <Form.Item label={'Upload Contract'} name='UserProfile'>
            <dt>Accepted extensions (images, pdf, docx, xsl)</dt>
            <div className='ucbx'>
              <Upload
                action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
                listType='picture-card'
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                maxCount={1}
                style={{ width: '100%' }}
              >
                {fileList?.length >= 1 ? null : uploadButton}
              </Upload>
              {isImageUploading && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                >
                  <Spin size='large' />
                </div>
              )}
            </div>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>

          <Form.Item
            label={'Assigned Clients to'}
            name='AdminUserlt'
            rules={[{ required: true, message: 'Please select clients' }]}
          >
            <Select
              mode='multiple'
              options={data?.rc.map((item: any) => ({
                label: item.Name,
                value: item.Id,
              }))}
              placeholder='Please select clients'
            />
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
