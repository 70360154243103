import { Form, Grid, Select, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { useGetAssignDetails } from 'features/assigned-clients/use-cases/get-assignId';
import { useUpdateAssign } from 'features/assigned-clients/use-cases/update-assign';
import { useGetUsersList } from 'features/users';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';

const { useBreakpoint } = Grid;

export const AssignModal = ({
  onClose,
  assignedData,
}: {
  onClose: () => void;
  assignedData: any;
}) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { isLoading, mutate } = useUpdateAssign();
  const [params] = useState({
    PageIndex: 1,
    RecordPerPage: 10,
    IsActive: true,
  });

  const { data: userListData } = useGetUsersList(params, { retry: false });
  const {
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
    data: assigndata,
    isError,
  } = useGetAssignDetails(`${assignedData?.id}/${assignedData?.companyId}`, {
    cacheTime: 0,
  });
  const idValue: any = [];
  //  const fectchId=assignedData?.assignAdminData?.array?.forEach((element:any) => {
  //  idValue.push({Id:element.id,Name:element.name});
  // });
  //  console.log(idValue,"idValue");
  //  console.log(fectchId,"fectchId")
  const isLoadingMail = {
    loading: isLoadingGet || isFetchingGet,
    isLoadingGet,
  };

  useEffect(() => {
    if (assigndata) {
      assignedData?.assignAdminData?.forEach((element: any) => {
        idValue.push({ Id: element.id, Name: element.name });
      });

      form.setFieldsValue({
        AdminUserlt: idValue?.map((item: any) => item?.Id),
      });
    }
  }, [assigndata, form, assignedData]);

  // const handleUpdate = (values: any) => {
  //   console.log(values, "Submitted Values");

  //   const formData = new FormData();
  //   formData.append('CompanyId', assignedData?.companyId);
  //   formData.append('AdminUserIds', JSON.stringify(values.AdminUserlt));

  //   mutate(formData, {
  //     onSuccess: () => {
  //       console.log("Update Successful");
  //       onClose();
  //     },
  //     onError: (error) => {
  //       console.error("Update Failed:", error);
  //     },
  //   });
  // };
  const handleUpdate = (values: any) => {
    // Construct the desired payload
    const payload = {
      companyId: assignedData?.companyId, // Use the existing companyId from assignedData
      assignAdminList: values.AdminUserlt?.map((userId: string) => ({
        id: userId, // Map each selected AdminUserlt ID to an object with an `id` field
      })),
    };
    // console.log(payload, "Payload to send");
    // // Use FormData only if necessary for your API; otherwise, send the JSON payload directly.
    // const formData = new FormData();
    // formData.append('payload', JSON.stringify(payload));
    mutate(payload, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Update Assigned Admin'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Update'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        {isLoadingGet ? (
          <Loader />
        ) : isError ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoadingMail.isLoadingGet}>
            <Form
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              onFinish={handleUpdate}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading}
            >
              <Form.Item
                label={'Assigned Clients to'}
                name='AdminUserlt'
                rules={[{ required: true, message: 'Please select clients' }]}
              >
                <Select
                  mode='multiple'
                  options={userListData?.rc?.map((item: any) => ({
                    label: item.Name,
                    value: item.Id,
                  }))}
                  placeholder='Please select clients'
                />
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
