import { Form, Grid, message } from 'antd';
import { Modal } from 'components';
import { useCreateDocuments } from 'features/assigned-clients/use-cases/create-documents';
import { useGetDocumentCategoryList } from 'features/assigned-clients/use-cases/get-category-document';
import { useGetCategoryList } from 'features/master-management/use-cases/get-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { convertFileSize } from 'routes/utils';

import { DocumentsInfoFormItems } from './document-info';

const { useBreakpoint } = Grid;

export const AddDocumentsModal = ({
  onClose,
  // selectedValue,
  year,
  month,
}: {
  onClose: () => void;
  // selectedValue: any;
  year: any;
  month: any;
}) => {
  const { id } = useParams();
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFormValues>();
  const { data } = useGetCategoryList({
    CompanyId: id,
  });
  const { refetch } = useGetDocumentCategoryList(id);
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false); // Loading state for image upload
  const [fileNames, setFileNames] = useState<string[]>([]);

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const { isLoading, mutate } = useCreateDocuments();
  const getInitialValues = {
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
  };

  const handleUpdate = (formValues: any) => {
    const IsClientUpload = false;
    // const formData = new FormData();
    if (!fileList || fileList.length === 0) {
      message.error('Please upload at least one file.');
      
return;
    }
    // Collect files and their blob URLs
    const files = fileList
      .map((fileItem, index) => {
        const file = fileItem.originFileObj;
        const fileSize = file.size;
        const documentName =
          fileList.length > 1
            ? `${formValues.DocumentName || 'Document'} (${index + 1})`
            : formValues.DocumentName || 'Document'; // No index if there's only one file

        if (!file) return null;
        const fileData = {
          documentName,
          fileName: fileNames[index] || '',
          fileSize: convertFileSize(fileSize),
          // blobUrl: blobUrl,  // Add blobUrl for each file
        };

        return fileData;
      })
      .filter((file) => file !== null);

    const payload = {
      companyId: id,
      periodId: 0,
      // documentId: id,
      serviceId:
        formValues.class || formValues.SubCategory || formValues.category || 0,
      isClientUpload: IsClientUpload,
      files: files, // Send files with their blob URLs
      year: +year,
      month: +month,
    };

    mutate(payload, {
      onSuccess: () => {
        onClose();
        refetch();
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={<span className='font-semibold'>Upload Documents</span>}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText={'Upload'}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
          className: 'btn cstmfull',
          disabled: isLoading || isImageUploading,
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <DocumentsInfoFormItems
            data={data}
            setFileList={setFileList}
            setPreviewImage={setPreviewImage}
            setPreviewOpen={setPreviewOpen}
            fileList={fileList}
            previewImage={previewImage}
            previewOpen={previewOpen}
            setIsImageUploading={setIsImageUploading}
            setFileNames={setFileNames}
            isImageUploading={isImageUploading}
          />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
