import { Badge, Tabs, TabsProps } from 'antd';
import { ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { StyledWrapper } from 'components/content-top-bar/top-content-bar.styles';
import { useGetAssignDetails } from 'features/assigned-clients/use-cases/get-assignId';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { AssignEditCategory } from './assign-category-edit';
import { AssignClassEditCategory } from './Class-assign-edit';
import { ClientsProfile } from './clients-profile';
import { LegalDocument } from './legal-document';
import { ManageUser } from './manage-user';
import { AssignEditRole } from './Role-edit-assign';
import { AssignSubCategory } from './sub-category-edit-assign';

export const EditNewClients = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('temp_clie');
  const assignvalue: any = localStorage.getItem('activeTabAssign');
  const QueryResult: any = useGetAssignDetails(`${ids}/${id}` as any, {
    cacheTime: 0,
  });
  const [activeTab, setActiveTab] = useState<string>(
    localStorage.getItem('activeTabAssign') || '1',
  );
  const { data } = QueryResult;
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <Badge>Client Profile</Badge>,
      children: <ClientsProfile />,
    },
    {
      key: '2',
      label: <Badge>Legal Document</Badge>,
      children: <LegalDocument assignvalue={assignvalue} />,
    },
    {
      key: '3',
      label: <Badge>Category</Badge>,
      children: <AssignEditCategory assignvalue={assignvalue} />,
    },
    {
      key: '4',
      label: <Badge>Sub Category</Badge>,
      children: <AssignSubCategory />,
    },
    {
      key: '5',
      label: <Badge>Class</Badge>,
      children: <AssignClassEditCategory assignvalue={assignvalue} />,
    },
    {
      key: '6',
      label: <Badge>Manage Users</Badge>,
      children: <ManageUser assignvalue={assignvalue} />,
    },
    {
      key: '7',
      label: <Badge>Manage Roles</Badge>,
      children: <AssignEditRole assignvalue={assignvalue} />,
    },
  ];
  const handleTabChange = (key: string) => {
    setActiveTab(key);
    localStorage.setItem('activeTabAssign', key);
  };
  useEffect(() => {
    setActiveTab('1');
  }, []);

  return (
    <StyledContentBackground className='editcompt'>
      <figure
        className='clbkbx'
        onClick={() => window.history.back()}
        role='button'
      >
        <ChevronLeft role='button' className='callbackarrow' />
        <ContentTopBar title={data?.rc[0]?.clientName} />
      </figure>
      {/* sd */}
      <StyledWrapper className='cstm_tabs'>
        <Tabs
          className='tsbrow'
          activeKey={activeTab}
          items={items}
          onChange={handleTabChange}
        />
      </StyledWrapper>
    </StyledContentBackground>
  );
};
