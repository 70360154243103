import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AssignClientsResponse,
  AssignedClientPendingDocumentList,
  AssignedClientsApiService,
} from 'features/assigned-clients/api/assign-clients-api.service';
import qs from 'qs';
const { getAssignedClientPendingDocumentList } = AssignedClientsApiService();

const getDocumentCategoryQuery = (
  params: AssignedClientPendingDocumentList,
  options?: UseQueryOptions<AssignClientsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<AssignClientsResponse>;
  options?: UseQueryOptions<AssignClientsResponse>;
} => ({
  queryKey: ['RejectedDocument', params],
  queryFn: ({ signal }) =>
    getAssignedClientPendingDocumentList(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetRejectedDocumentList = (
  params: any,
  options?: UseQueryOptions<AssignClientsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<AssignClientsResponse, Error>(
    getDocumentCategoryQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
      cacheTime: 1000 * 60 * 10, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Avoid unnecessary refetch on window focus
      ...options,
    }),
  );
};
