import { AlertOutlined, CloseOutlined } from '@ant-design/icons';
import { useNotificationRead } from 'features/assigned-clients/use-cases/notification-read';
import { DashBoardApiService } from 'features/Dashboard/api/dashboard-api.service';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationValue } from 'types/notification-dashboard';

export const NotificationDocuments = () => {
  const [notification, SetNotification] = useState<any>(null);
  const navigate = useNavigate(); // Initialize navigation
  const { mutate } = useNotificationRead();
  const initial = async () => {
    try {
      const apiRes = await DashBoardApiService().GetNotifications('' as any);
      SetNotification(apiRes);
    } catch (error) {
      console.error('Error fetchings client status graph:', error);
    }
  };
  const notificationRead = (item: any) => {
    const formData = new FormData();
    formData.append('NotificationId', item?.notificationId);
    mutate(formData, {
      onSuccess: () => {
        initial();
      },
    });
  };
  const handleCloseNotification = (item: any) => {
    notificationRead(item);
  };
  const handleNotificationClick = (item: any) => {
    if (item?.notificationTitle === NotificationValue.DocumetComment) {
      notificationRead(item);
      navigate(
        `/assign-client/${item?.companyId}/detail?clsid=${
          item?.clientId
        }&activeTab=${'4'}`,
      ); // Replace with the correct route
    } else if (
      item?.notificationTitle === NotificationValue.Adminrequesttouploaddocument
    ) {
      notificationRead(item);
      navigate(
        `/assign-client/${item?.companyId}/detail?clsid=${
          item?.clientId
        }&activeTab=${'2'}`,
      ); // Replace with the correct route
    } else if (
      item?.notificationTitle === NotificationValue.Clientuploaddocument
    ) {
      notificationRead(item);
      navigate(
        `/assign-client/${item?.companyId}/detail?clsid=${
          item?.clientId
        }&activeTab=${'1'}`,
      ); // Replace with the correct route
    } else {
      notificationRead(item);
    }
  };
  useEffect(() => {
    initial();
  }, []);

  return (
    <Fragment>
      <div className='card'>
        <div>
          <div className='head'>
            <h3>Notifications</h3>
          </div>
          {notification?.rc.map((item: any, index: number) => {
            return (
              <div className='nitybx' key={index}>
                <div className='nblst'>
                  <div className='nlicon'>
                    <AlertOutlined />
                  </div>
                  <div
                    className='nl_vlu'
                    role='button'
                    onClick={() => handleNotificationClick(item)}
                  >
                    <h3>{item?.notificationTitle}</h3>
                    <h6>{item?.notificationBody}</h6>
                    <p>{item?.notificationTime}</p>
                  </div>
                  <div
                    role='button'
                    onClick={() => handleCloseNotification(item)}
                  >
                    <CloseOutlined />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
