import { Form, Grid, Input, message, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import {
  mailFormField,
  mailformValue,
} from 'features/new-clients/components/clients-form/type';
import { useGetClientsDetails } from 'features/new-clients/use-cases/get-client';
import { useUpdateMail } from 'features/new-clients/use-cases/update-email';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
// ../clients-form/type
const { useBreakpoint } = Grid;

export const EmailViewModal = ({
  onClose,
  reminderId,
}: {
  onClose: () => void;
  reminderId: any;
}) => {
  const formName = 'add-category-form';
  // console.log(reminderId?.CompanyId,"reminderId");

  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<mailformValue>();
  const { isLoading, mutate } = useUpdateMail();
  const userQueryResult: any = useGetClientsDetails(
    `${reminderId?.Id}/${reminderId?.CompanyId}`,
    {
      cacheTime: 0,
    },
  );
  const {
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
    data: Maildata,
    isError,
  } = userQueryResult;
  const isLoadingMail = {
    loading: isLoadingGet || isFetchingGet,
    isLoadingGet,
  };
  useEffect(() => {
    if (Maildata) {
      form.setFieldsValue({
        [mailFormField.Email]: Maildata?.rc[0]?.email,
      });
    }
  }, [Maildata, form]);
  const handleUpdate = (formValues: mailformValue) => {
    const formData = new FormData();
    formData.append('CustomerId', reminderId?.Id);
    formData.append('Email', formValues?.Email);
    mutate(formData, {
      onSuccess: (data: any) => {
        if (data?.rs == 1) {
          message.success('Email Update successfully');
        } else {
          message.error(data?.rm);
        }
        onClose();
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Update Email'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Save'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        {isLoadingGet ? (
          <Loader />
        ) : isError ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoadingMail.isLoadingGet}>
            <Form<mailformValue>
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              // initialValues={getInitialValues}
              onFinish={handleUpdate}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading}
            >
              <Form.Item
                label='Email'
                name={mailFormField.Email}
                rules={[
                  { required: true, message: 'Email is required' }, // Mandatory field validation
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  }, // Email format validation
                ]}
              >
                <Input placeholder='Enter Email' autoComplete='off' />
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
