import { EllipsisOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Flex,
  Menu,
  Pagination,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { useGetRejectedDocumentList } from 'features/assigned-clients/use-cases/reject-document';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Link2Off } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { formatUtcToLocal } from 'routes/utils';

const { Title } = Typography;
export const RejectDocumentList = ({
  year,
  month,
  searchName,
  activeTabValue,
}: // selectedValue,
any) => {
  const { id: CompanyId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9); // Number of items per page

  const {
    data: rejectedDocument,
    isFetching,
    refetch,
  } = useGetRejectedDocumentList({
    CompanyId,
    DocumentStatusId: 3,
    Year: year,
    Month: month,
    Name: searchName,
    ClientId: ids,
    PageIndex: currentPage,
    RecordPerPage: itemsPerPage,
    // PeriodId: selectedValue || 0,
  });
  const totalRecords = rejectedDocument?.rc?.[0]?.totalRecords || 0;
  const paginatedData = rejectedDocument?.rc || [];
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  useEffect(() => {
    if (activeTabValue == '3') {
      refetch();
    }
  }, [refetch, year, month, searchName, activeTabValue, currentPage]);

  return (
    <Fragment>
      {isFetching ? (
        <Spin className='ldrloder' />
      ) : (
        <Row className='fldrbx'>
          {Array.isArray(paginatedData) && paginatedData?.length ? (
            paginatedData?.map((item: any, index: number) => (
              <Card key={index}>
                <Flex>
                  <Typography className='meidafolder meida'>
                    {item?.documentPath == null ? (
                      <Tooltip title={'Invaild Path'}>
                        <Link2Off />
                      </Tooltip>
                    ) : (
                      <FileViewer documentPath={item.documentPath} />
                    )}
                  </Typography>

                  <Typography className='cvnu'>
                    <Title className='crdttl' level={3}>
                      {item.documentName}asd
                      <dt className='stsbx'>
                        <dt
                          className={
                            item.documentStatus === 'Approved'
                              ? 'approved'
                              : item.documentStatus == 'Pending'
                              ? 'pending'
                              : 'rejected'
                          }
                        >
                          {item?.documentStatus == 'Reject'
                            ? 'Rejected'
                            : item?.documentStatus}
                        </dt>
                      </dt>
                      <dt className='mnubdrp'>
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key='download'>
                                <a
                                  href={item.documentPath}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  Download
                                </a>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['click']}
                          placement='bottomRight'
                        >
                          <Button type='text' icon={<EllipsisOutlined />} />
                        </Dropdown>
                      </dt>
                    </Title>
                    <dt className='tmrbx'>
                      {item?.totalComments
                        ? `${item?.totalComments} Comments`
                        : '0 Comments'}
                    </dt>
                    {item.documentSize != null ? (
                      <dt className='tmrbx'>Size : {item.documentSize} </dt>
                    ) : (
                      ''
                    )}

                    <dt className='tmrbx'>
                      Last Update:{' '}
                      {/* {dayjs(item.lastUpdatedDate).format(
                        'DD MMM YYYY | hh:mm A',
                      )} */}
                      {formatUtcToLocal(item.lastUpdatedDate)}
                      {/* 24 Sep, 2024 | 03:11 PM */}
                    </dt>
                  </Typography>
                </Flex>
              </Card>
            ))
          ) : (
            <Col span={24}>
              <Empty description='No Rejected Documents Found' />
            </Col>
          )}
        </Row>
      )}
      {totalPages > 1 && (
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalRecords}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Fragment>
  );
};
