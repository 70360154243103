import { Form, Grid, Input, message, Select } from 'antd';
import { Modal } from 'components';
import { MasterApiService } from 'features/master-management/api/master-api.service';
import { ClassFormField } from 'features/master-management/components/filter-field/type';
import { useCreateClassCategory } from 'features/master-management/use-cases/create-class';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { useGetClientsCetgoryList } from 'features/new-clients/use-cases/get-clients-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const { useBreakpoint } = Grid;

export const AddEditClassCategoryUserModal = ({
  onClose,
  editData,
  setEditData,
  refetch,
}: {
  onClose: () => void;
  editData: any;
  setEditData: any;
  refetch: any;
}) => {
  const formName = 'add-category-form';
  const { id } = useParams();
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { data } = useGetClientsCetgoryList({ CompanyId: id });
  const { data: Frequency } = useDropDownFlag('Frequency');
  const { isLoading, mutate } = useCreateClassCategory();
  const handleClose = () => {
    setEditData(null); // Clear edit data
    form.resetFields();
    onClose(); // Close modal
  };
  const [subCaetgory, setSubCategory] = useState<any>('');
  const subCategory = async (id1: string) => {
    try {
      const apiRes = await MasterApiService().GetSubCategoryById(
        `CategoryId=${id1}` as any,
      );
      setSubCategory(apiRes);
    } catch (error) {
      message.error('something is wrong');
    }
  };
  const handleAdd = (formvalue: any) => {
    // const formdata = {
    //   className: formvalue?.className,
    //   subCategoryId: formvalue?.subCategoryId,
    //   categoryId: formvalue?.categoryId,
    //   frequencyId: formvalue?.frequencyId,
    //   companyId: id
    // }
    let formdata: any;
    if (editData?.id) {
      formdata = {
        className: formvalue?.className,
        subCategoryId: formvalue?.subCategoryId,
        categoryId: formvalue?.categoryId,
        // frequencyId: formvalue?.frequencyId,
        // companyId: id,
        companyId: id,
        ...(editData?.id && { id: editData?.id }), //
      };
    } else {
      formdata = {
        className: formvalue?.className,
        subCategoryId: formvalue?.subCategoryId,
        categoryId: formvalue?.categoryId,
        frequencyId: formvalue?.frequencyId,
        companyId: id,
      };
    }
    mutate(formdata, {
      onSuccess: () => {
        refetch(); // Refresh data
        onClose(); // Close the modal
        form.resetFields();
        // Show a success message based on the presence of editData
        if (editData?.id) {
          message.success('Category updated successfully');
        } else {
          message.success('Category created successfully');
        }
      },
    });
  };
  useEffect(() => {
    // Set initial form values when editData changes
    if (editData) {
      form.setFieldsValue({
        categoryId: editData.categoryId, // Assuming `editData.text` contains the category name
        subCategoryId: editData?.subCategoryId,
        className: editData?.className,
      });
    } else {
      form.resetFields(); // Clear form if there's no editData
    }
  }, [editData, form]);
  useEffect(() => {
    if (editData?.categoryId) {
      subCategory(editData.categoryId); // Call subCategory when categoryId exists
    }
  }, [editData?.categoryId]);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={editData ? 'Edit ClassName' : 'Create ClassName'}
        onCancel={handleClose}
        getContainer={false}
        onOk={form.submit}
        okText={editData ? 'Edit ClassName' : 'Create ClassName'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          onFinish={handleAdd}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item name={'categoryId'} label={'Category'}>
            <Select
              options={data?.rc?.map((item: any) => ({
                label: item.categoryName,
                value: item.id,
              }))}
              onChange={(value) => subCategory(value)}
              placeholder='please select'
            />
          </Form.Item>
          <Form.Item name={'subCategoryId'} label={'Sub Category'}>
            <Select
              options={subCaetgory?.rc?.map((item: any) => ({
                label: item.subCategoryName,
                value: item.id,
              }))}
              placeholder={`${
                !subCaetgory?.rc?.length ? 'No Subactegory' : 'Please Select'
              }`}
            />
          </Form.Item>
          <Form.Item name={'className'} label={'ClassName'}>
            <Input placeholder='Enter ClassName' />
          </Form.Item>
          {!editData?.id && (
            <Form.Item<ClassFormField> name={'frequencyId'} label='Frequency'>
              <Select
                options={Frequency?.data?.rc.map((item: any) => ({
                  label: item.text, // Use `label` for the display value
                  value: item.value, // Use `value` for the actual value
                }))}
                placeholder='Please select'
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
