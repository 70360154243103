import { Form, Grid, Input, message, Select } from 'antd';
import { Modal } from 'components';
import { useCreateSubCategory } from 'features/master-management/use-cases/create-subCategory';
import { useDropDownFlag } from 'features/new-clients/use-cases/drop-down';
import { useGetClientsCetgoryList } from 'features/new-clients/use-cases/get-clients-category';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const { useBreakpoint } = Grid;

export const AddEditSubCategoryUserModal = ({
  onClose,
  editData,
  setEditData,
  refetch,
}: {
  onClose: () => void;
  editData: any;
  setEditData: any;
  refetch: any;
}) => {
  const formName = 'add-category-form';
  const { id } = useParams();
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { data } = useGetClientsCetgoryList({ CompanyId: id });
  const { isLoading, mutate } = useCreateSubCategory();
  const { data: Frequency } = useDropDownFlag('Frequency');

  const handleClose = () => {
    setEditData(null); // Clear edit data
    onClose(); // Close modal
  };
  const handleUpdate = (formData: any) => {
    // const formvalue:any={
    //   subCategoryName:formData?.subCategoryName,
    //   categoryId:formData?.categoryId,
    //   frequencyId:formData?.frequencyId,
    //   companyId:id
    // }
    let formvalue: any;
    if (editData?.id) {
      formvalue = {
        subCategoryName: formData?.subCategoryName,
        categoryId: formData?.categoryId,
        companyId: id,
        ...(editData?.id && { id: editData?.id }), //
      };
    } else {
      formvalue = {
        subCategoryName: formData?.subCategoryName,
        categoryId: formData?.categoryId,
        frequencyId: formData?.frequencyId,
        companyId: id,
      };
    }
    mutate(formvalue, {
      onSuccess: () => {
        onClose(); // Close the modal on success
        refetch(); // Refresh data (ensure `refetch` is defined and imported)
        if (editData?.id) {
          message.success('SubCategory updated successfully');
        } else {
          message.success('SubCategory created successfully');
        }
      },
    });
  };
  useEffect(() => {
    // Set initial form values when editData changes
    if (editData) {
      form.setFieldsValue({
        categoryId: editData.categoryId, // Assuming `editData.text` contains the category name
        subCategoryName: editData?.subCategoryName,
      });
    } else {
      form.resetFields(); // Clear form if there's no editData
    }
  }, [editData, form]);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={editData ? 'Edit SubCategory' : 'Create SubCategory'}
        onCancel={handleClose}
        getContainer={false}
        onOk={form.submit}
        okText={editData ? 'Edit SubCategory' : 'Create SubCategory'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item
            name='subCategoryName'
            label='Sub Category Name'
            rules={[
              {
                required: true,
                message: 'Sub Category Name name is required.',
              },
            ]}
          >
            <Input placeholder='Enter Sub Category Name Name' />
          </Form.Item>
          <Form.Item name={'categoryId'} label={'Category'}>
            <Select
              options={data?.rc?.map((item: any) => ({
                label: item.categoryName,
                value: item.id,
              }))}
              placeholder='please select'
            />
          </Form.Item>
          {!editData?.id && (
            <Form.Item name={'frequencyId'} label='Frequency'>
              <Select
                options={Frequency?.data?.rc.map((item: any) => ({
                  label: item.text,
                  value: item.value,
                }))}
                placeholder='Please select'
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
