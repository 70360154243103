import { Button, Card, Col, Empty, Flex, Row, Spin, Typography } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { RequestDocumentModal } from 'features/assigned-clients/components/modals/request-document-modal';
import { useGetRequestedDocumentList } from 'features/assigned-clients/use-cases/request-document';
import { CirclePlus, FileText } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { formatUtcToLocal, MonthValue } from 'routes/utils';

import { RejectedPendingDocuments } from './rejected-pending';
import { ReminderViewItemModal } from './reminder-modal';

const { Title } = Typography;

export const RequestDocumentView = ({
  clientId,
  setAddCommentsModal,
  setCommentsInfo,
  setMoveToModal,
  setDocumentsInfo,
  year,
  month,
  searchName,
  // selectedValue,
  // selectFilterValue,
  getDocumentsCount,
  initilaMarkFinished,
  finishedValue,
  requestdocument,
  handleClick,
}: {
  clientId: string;
  setCommentsInfo: any;
  setAddCommentsModal: any;
  setMoveToModal: any;
  setDocumentsInfo: any;
  year: any;
  month: any;
  searchName: any;
  // selectedValue: any;
  // selectFilterValue: any;
  getDocumentsCount: any;
  initilaMarkFinished: any;
  finishedValue: any;
  requestdocument: any;
  handleClick: any;
}) => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const [reminderModal, setReminderModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestedModal, setRequestedModal] = useState(false);
  const [pendingDocumentData, setpendingDocumentData] = useState<any>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Number of items per page
  const requestedDocumentParams = {
    CompanyId: id,
    DocumentStatusId: 0,
    PageIndex: 1,
    RecordPerPage: 10,
    Year: year,
    Month: month,
    Name: searchName,
    ClientId: ids,
    // PeriodId: selectedValue || 3,
  };
  const { data: rquestDocument, refetch } = useGetRequestedDocumentList(
    requestedDocumentParams,
  );
  const activetab = localStorage.getItem('activeTab'); // Fetch the activeTab value
  const getMonthName = (monthNumber: number): string => {
    if (monthNumber >= 1 && monthNumber <= 12) {
      return MonthValue[monthNumber - 1]; // Adjust for 0-based index
    }

    return 'Invalid Month'; // Fallback for invalid numbers
  };
  const monthNumber = month;
  const titleText = `${getMonthName(monthNumber)} ${year}`;
  const intialFetch = async () => {
    try {
      setIsLoading(true);
      const apiRes = await AssignedClientsApiService().adminRequestDocumentList(
        {
          CompanyId: id,
          PageIndex: currentPage,
          RecordPerPage: itemsPerPage,
          DocumentStatusId: 1, // for requested
          Year: year,
          Month: month,
          Name: searchName,
          ClientId: ids,
          // PeriodId: selectedValue,
        } as any,
      );
      setpendingDocumentData(apiRes);
    } catch (error) {
      console.error('Error fetching requested documents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    intialFetch();
    refetch();
  }, [year, month, searchName]);
  useEffect(() => {
    if (activetab === '2') {
      initilaMarkFinished();
      intialFetch();
      refetch();
      getDocumentsCount();
    }
  }, [year, month, activetab, currentPage]); // Dependency on finishedValue
  if (isLoading) return <Spin className='ldrloder' />;

  return (
    <Fragment>
      <Flex className='crdsubhed' justify='space-between'>
        <Title level={3}>{titleText}</Title>
        {
          <ButtonGroup>
            {(!finishedValue?.rc ||
              finishedValue?.rc.length === 0 ||
              finishedValue?.rc[0]?.status === 1) && (
              <button
                className='btn btn-primary flex align-center'
                onClick={() => setRequestedModal(true)}
              >
                <CirclePlus height={'18px'} className='mr-2' />
                Request New Document
              </button>
            )}
          </ButtonGroup>
        }
      </Flex>
      {/* Requested Documents */}
      <Row className='fldrbx'>
        {Array.isArray(rquestDocument?.rc) &&
        (rquestDocument?.rc?.length as any) > 0 ? (
          rquestDocument?.rc.map((item: any, index: number) => (
            <Card key={index} role='button'>
              <Flex>
                <div className='meidafolder meida'>
                  <FileText stroke='#7D819E' className='meidafolder' />
                </div>

                <Typography className='cvnu'>
                  <Title className='crdttl' level={3}>
                    {item.documentName}
                  </Title>

                  <dt className='tmrbx'>
                    {item.categoryName}
                    {item.subCategoryName}
                  </dt>
                  {item.documentSize && (
                    <dt className='tmrbx'>Size: {item.documentSize}</dt>
                  )}
                  {item.lastUpdatedDate && (
                    <dt className='tmrbx'>
                      Last Update: {formatUtcToLocal(item.lastUpdatedDate)}
                    </dt>
                  )}

                  <dt className='button_group'>
                    <div>
                      <Button
                        className='btn'
                        onClick={() => setReminderModal(true)}
                      >
                        Send Reminder
                      </Button>
                    </div>
                  </dt>
                </Typography>
              </Flex>
            </Card>
          ))
        ) : (
          <Col span={24}>
            <Empty description='No requested documents available.' />
          </Col>

          // <dt className='px-3'></dt>
          // <Typography.Text></Typography.Text>
        )}
      </Row>

      {/* Pending Documents */}
      {pendingDocumentData?.rc?.length > 0 && ( // if any bug then add pendingDocumentdata
        // pendingDocumentData?.rc?.length > 0 &&
        <Row>
          <Col span={24}>
            <Title level={4}>Pending Documents</Title>
            <RejectedPendingDocuments
              pendingDocument={pendingDocumentData}
              companyId={id}
              reFetchPendingDocument={intialFetch}
              setAddCommentsModal={setAddCommentsModal}
              setCommentsInfo={setCommentsInfo}
              setMoveToModal={setMoveToModal}
              setDocumentsInfo={setDocumentsInfo}
              refetch={intialFetch}
              getDocumentsCount={getDocumentsCount}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              requestdocument={requestdocument}
              handleClick={handleClick}
            />
          </Col>
        </Row>
      )}

      {reminderModal && (
        <ReminderViewItemModal
          onClose={() => setReminderModal(false)}
          reminderId={clientId}
        />
      )}
      {requestedModal && (
        <RequestDocumentModal
          onClose={() => setRequestedModal(false)}
          refetch={refetch}
          // selectedValue={selectedValue}
          year={year}
          month={month}
          getDocumentsCount={getDocumentsCount}
        />
      )}
    </Fragment>
  );
};
