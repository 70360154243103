import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AssignClientsResponse,
  AssignedClientsApiService,
} from 'features/assigned-clients/api/assign-clients-api.service';

import { AssignDetailClientsQueryKeys } from './query-keys';

const { GetAssignById } = AssignedClientsApiService();

export const useGetAssignDetails = (
  id: string,
  options?: UseQueryOptions<AssignClientsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<AssignClientsResponse, Error>(
    AssignDetailClientsQueryKeys.detail(id), // Query Key
    async ({ signal }) => {
      const response = await GetAssignById(id, {
        config: { signal },
      });
      
return response; // Assuming this returns AssignClientsResponse
    },
    {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);
        message.error(uiMessage);
      },
      staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
      cacheTime: 1000 * 60 * 10, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Avoid unnecessary refetch on window focus
      ...options, // Merge with passed options
    } as any,
  );
};
