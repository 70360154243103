import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const StyledUserModalWrapper = styled.div`
  ${BREAKPOINTS.laptop} {
    .ant-modal-body {
      // max-height: 80vh;
      //overflow-y: auto;
    }
  }
  .ant-upload-list-item.ant-upload-list-item-error {
    border: 1px solid #d9d9d9 !important;
    svg {
      height: 20px;
      color: #333;
      path[fill='#1677ff'] {
        fill: #333 !important;
      }
      path[fill='#e6f4ff'] {
        fill: #f6f6f6 !important;
      }
    }
    .ant-upload-list-item-name {
      color: #333;
    }
  }
`;
