import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { DashBoardApiService } from 'features/Dashboard/api/dashboard-api.service';
import { queryClient } from 'providers';

import { DashBoardQueryKeys } from './query-keys';

const { NotificationSeen } = DashBoardApiService();

export const useNotificationSeen = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: FormData) => {
      return NotificationSeen(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        queryClient.invalidateQueries({
          queryKey: DashBoardQueryKeys.all,
        });
        if (data?.rs == 1) {
          message.success(data?.rm);
        }
      },
    },
  );
};
