import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { Gender, User } from 'types';

export interface UserListParams extends PaginatedRequestParams {
  full_user_name?: string;
  phone_number?: string;
  roles?: string[];
  company_id?: string;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}

export type CreateUserDTO = {
  UserName?: string;
  Email?: string | null;
  RoleId: string;
};

export type UpdateUserDTO = {
  company_id?: string;
  email?: string | null;
  first_name?: string;
  gender?: Gender | null;
  id: string;
  last_name?: string;
  password?: string;
  phone_number?: string;
  is_active?: boolean;
  role?: string;
};

export type UserResponse = PaginatedResponse<User>;

export const UserApiService = () => {
  const createUser = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/UserManagement/CreateUser`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getUser = async (id: string, callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/UserManagement/GetUserList?PageIndex=1&RecordPerPage=10&Id=${id}`,
    });

    return response.data;
  };
  const getMenuItem = async (callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: '/UserManagement/GetMenuList',
    });

    return response.data;
  };

  const getUsers = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<UserResponse>({
      ...callConfig,
      endpoint: `/UserManagement/GetUserList`,
      params,
    });

    return response.data;
  };
  const getRole = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<UserResponse>({
      ...callConfig,
      endpoint: `/RoleManagement/GetRoleList`,
      params,
    });

    return response.data;
  };

  const updateUser = async (
    payload: UpdateUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/UserManagement/UpdateUser`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const updateStatus = async (
    payload: UpdateUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/UserManagement/UpdateUserStatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getRolePermission = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/RoleManagement/GetPermissionlist`,
      params,
    });

    return response.data;
  };
  const updateMail = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/UserManagement/VerifyEmail`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const ChangePasswordProfile = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/UserManagement/ChangePassword`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const OtpVerfiedUser = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/UserManagement/VerifyEmailOtp`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const ProfileUpdate = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/UserManagement/UpadteUserName`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const UploadImageProfile = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/UserManagement/UploadUserImage`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  return {
    createUser,
    getUser,
    getUsers,
    updateUser,
    getRole,
    getMenuItem,
    updateStatus,
    getRolePermission,
    updateMail,
    ChangePasswordProfile,
    UploadImageProfile,
    OtpVerfiedUser,
    ProfileUpdate,
  };
};
