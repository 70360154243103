import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import qs from 'qs';

import { AssignClientsQueryKeys } from './query-keys';

const { getAttentionDocument } = AssignedClientsApiService();

const getAssignedClientsQuery = (
  params: any,
  options?: UseQueryOptions<any>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<any>;
  options?: UseQueryOptions<any>;
} => ({
  queryKey: AssignClientsQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getAttentionDocument(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetAttentionDocumentList = (
  params: any,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getAssignedClientsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);
        message.error(uiMessage);
      },
      staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
      cacheTime: 1000 * 60 * 10, // Keep data in cache for 10 minutes
      refetchOnWindowFocus: false, // Avoid unnecessary refetch on window focus
      ...options,
    }),
  );
};
