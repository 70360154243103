import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Grid, message, Popconfirm, Space, Switch, Table } from 'antd';
import { Column } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { useCategoryUserStatus } from 'features/assigned-clients/use-cases/category-user-status';
import {
  CategoryFilterFormValuesPaginated,
  CategoryFormFilterValues,
} from 'features/master-management/components/filter-field/type';
import { useGetClientsCetgoryList } from 'features/new-clients/use-cases/get-clients-category';
import { usePaginationParams } from 'hooks';
import { FilePenLine, Trash2 } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CategoryClientsFilterForm } from './form-filter/category-clients-filter';
import { AddCategoryUserModal } from './modal/add-category-user';

const { useBreakpoint } = Grid;

export const AssignEditCategory = ({ assignvalue }: any) => {
  const screens = useBreakpoint();
  const getInitialFilterValues: CategoryFormFilterValues = {
    CategoryName: undefined,
  };
  const { params, updateParams, resetPage } =
    usePaginationParams<CategoryFilterFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });
  const { id } = useParams();
  const {
    data: dataList,
    isFetching,
    refetch,
  } = useGetClientsCetgoryList({
    ...params,
    CompanyId: id,
  });
  const [loadingStatus, setLoadingStatus] = useState<{
    [key: number]: boolean;
  }>({});

  const { mutate: CategoryUserStatus, isLoading: globalStatusLoading } =
    useCategoryUserStatus();
  const [showAddModal, setShowAddModal] = useState(false);
  const [editData, setEditData] = useState('');

  const onChangeDelete = (record: any) => {
    const CetgoryDelete = {
      flag: 'DELETE',
      companyId: id,
      seviceId: record.id || '',
      status: record.status,
    };
    CategoryUserStatus(CetgoryDelete, {
      onSuccess: (data: any) => {
        if (data?.rs == 1) {
          message.success('Category Delete successfully');
        } else {
          message.error('Cannot delete category as it is linked to a document');
        }
      },
    });
  };

  const handleOpenEdit = (item: any) => {
    setEditData(item);
    setShowAddModal(true);
  };

  const handleStatusChange = (record: any, checked: boolean) => {
    const formData = {
      flag: 'UPDATE',
      companyId: id,
      seviceId: record.id || '',
      status: checked,
    };
    setLoadingStatus((prev) => ({ ...prev, [record.id]: true }));
    CategoryUserStatus(formData, {
      onSuccess: (data) => {
        setLoadingStatus((prev) => ({ ...prev, [record.id]: false }));
        if (data?.rs == 1) {
          message.success('Status Changed successfully');
        } else {
          message.error(data?.rm);
        }
      },
      onError: () => {
        setLoadingStatus((prev) => ({ ...prev, [record.id]: false }));
      },
    });
  };

  const handleFiltersChange = (values: CategoryFormFilterValues) => {
    updateParams(values);
    resetPage();
  };

  const formId = 'role-filters-form';

  useEffect(() => {
    if (assignvalue == 3) {
      refetch();
    }
  }, [assignvalue]);

  return (
    <Fragment>
      <CategoryClientsFilterForm
        formId={formId}
        handleFilter={handleFiltersChange}
        initialValues={getInitialFilterValues}
      />
      <StyledTableWrapper className='card grid_secton'>
        <StyledTableHeader className='gs_head'>
          <h3>Categories List</h3>

          <div className='buttongroup'>
            <Button
              icon={<PlusCircleOutlined />}
              style={{ backgroundColor: '#223666', color: 'white' }}
              onClick={() => {
                setEditData(null as any);
                setShowAddModal(true);
              }}
            >
              {screens.lg ? <>Add New Category</> : <>Add New</>}
            </Button>
            {showAddModal && (
              <AddCategoryUserModal
                onClose={() => setShowAddModal(false)}
                editData={editData}
                setEditData={setEditData}
                refetch={refetch}
              />
            )}
          </div>
        </StyledTableHeader>
        <div className='cstmtblbx w-full'>
          <div className='cstm_table'>
            <Table
              loading={isFetching}
              dataSource={dataList?.rc as any}
              rowKey='id'
            >
              <Column
                width={'125px'}
                title={'Sr No'}
                render={(_, __, index) => {
                  return index + 1;
                }}
              />
              <Column title={'Category Name'} dataIndex={'categoryName'} />
              <Column
                width={'125px'}
                title={'Status'}
                dataIndex={'status'}
                render={(_, record: any) => (
                  <span
                    style={{
                      color: record.isActive === true ? '#155724' : 'red',
                    }}
                  >
                    {record?.isActive == true ? 'Active' : 'InActive'}
                  </span>
                )}
              />
              <Column
                width='135px'
                title={'Action'}
                dataIndex={'Id'}
                render={(_, record: any) => (
                  <Space className='grdbtngroup'>
                    {!record?.isDefault && (
                      <Button
                        className='btn'
                        type='link'
                        onClick={() => {
                          setShowAddModal(true);
                          handleOpenEdit(record);
                        }}
                      >
                        <FilePenLine height={16} />
                      </Button>
                    )}
                    <Popconfirm
                      title='Delete the Category'
                      description='Are you sure to delete this Category?'
                      okText='Yes'
                      cancelText='No'
                      onConfirm={() => onChangeDelete(record)}
                    >
                      <Button className='btn' type='link'>
                        <Trash2 stroke='#E04343' height={16} />
                      </Button>
                    </Popconfirm>

                    <Switch
                      disabled={loadingStatus[record.id] || globalStatusLoading}
                      loading={loadingStatus[record.id]}
                      onChange={(checked) =>
                        handleStatusChange(record, checked)
                      }
                      checked={record.isActive}
                    />
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </StyledTableWrapper>
    </Fragment>
  );
};
