import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Grid,
  Image,
  Input,
  message,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';
import { DocumentView } from 'features/assigned-clients/components/assign-details/documents/document-view';
import { RejectDocumentList } from 'features/assigned-clients/components/assign-details/reject-document/reject-document';
import { RequestDocumentView } from 'features/assigned-clients/components/requested-documents/requested-documents';
import { useCreateUniversalLink } from 'features/assigned-clients/use-cases/create-UniversalLink';
import { useDocumentsCount } from 'features/assigned-clients/use-cases/document-count';
import { useGetMarkFinished } from 'features/assigned-clients/use-cases/get-markFinished';
import { useGetUniversalLink } from 'features/assigned-clients/use-cases/get-universalLink';
import { ChevronLeft } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { disabledDate, formatUtcToLocal, getRoute } from 'routes/utils';

import { AttentionDocumentView } from './attention-document/attention-document';
import { CommentsItemView } from './comments/add-comments';
import { AddDocumentsModal } from './documents-modal/add-document';
import { ManagementReportView } from './management-report/management-report';
const { Title } = Typography;

export const AssignViewInfoDetails = ({ Id }: any) => {
  const naivate = useNavigate();
  const location = useLocation();
  const searchParams: any = new URLSearchParams(location.search);
  const id = searchParams.get('clsid');
  const { mutate: getMarkfinished, data: finishedValue } = useGetMarkFinished();
  const activeTabValue = searchParams.get('activeTab');
  // const { data: clientData } = useGetAssignDetails(`${id}/${Id}`,{
  //   enabled:!!id
  // });
  const [clientData, setClientData] = useState<any>('');
  const initail = async () => {
    try {
      const apires: any = await AssignedClientsApiService().GetAssignById(
        `${id}/${Id}`,
      );
      setClientData(apires);
    } catch (error: any) {
      message.error(error);
    }
  };

  const clientId: any = clientData?.rc?.[0]?.clientId;
  const [addCommentsModal, setAddCommentsModal] = useState(false);
  const [commentsInfo, setCommentsInfo] = useState('');
  const [documentUploadModal, setDocumentUploadModal] = useState(false);
  const [year, setYear] = useState(dayjs().year().toString());
  const [month, setMonth] = useState((dayjs().month() + 1).toString()); // Month is 0-indexed
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [commentsMarkInfo, setCommentsMarkInfo] = useState('');
  const [subCategoryData, setSubCategoryData] = useState({}); // categorydata
  const [secondCategoryData, setSecondCategoryData] = useState({}); // subsecondcategory
  const [movetoModal, setMoveToModal] = useState(false);
  const [documentInfo, setDocumentsInfo] = useState('');
  const [searchName, setSearchName] = useState('');
  const [activeTab, setActiveTab] = useState<string>(
    localStorage.getItem('activeTab') || '1',
  );
  const { mutate: getCount, data: apiCountValue } = useDocumentsCount();
  const { mutate: generateUniversalLink, isLoading } = useCreateUniversalLink();
  const { mutate: getUniversalLink, data: getValueLink } =
    useGetUniversalLink();
  const getDocumentsCount = () => {
    const formdata = {
      companyId: Id,
      Year: +year,
      Month: +month,
    };
    getCount(formdata);
  };
  const getUniversalLinkData = () => {
    const payloadUni: any = {
      companyId: Id,
    };
    getUniversalLink(payloadUni);
  };
  const handleDateChange = (date: any) => {
    if (date) {
      // Update to the selected date
      setSelectedDate(date);
      setYear(date.year().toString());
      setMonth((date.month() + 1).toString());
    } else {
      // Reset to the current date when cleared
      const currentDate = dayjs();
      setSelectedDate(currentDate);
      setYear(currentDate.year().toString());
      setMonth((currentDate.month() + 1).toString());
    }
  };
  const { useBreakpoint } = Grid;
  const formattedDate = formatUtcToLocal(clientData?.rc?.[0]?.lastUpdate);
  const [countValue, setCountValue] = useState<Record<string, number>>({
    pendingDocument: 0,
    requestedPendingDocument: 0,
    rejectedDocument: 0,
    attentionDocument: 0,
  });

  const screens = useBreakpoint();
  const initilaMarkFinished = () => {
    const FinishedData: any = {
      companyId: Id,
      Year: +year,
      Month: +month,
      ClientId: id,
      // periodId: selectedValue,
    };
    getMarkfinished(FinishedData);
  };
  const [documentCheck, setDocument] = useState('');
  const [requestdocument, setRequestDocument] = useState('');
  const handleClick = (key: string) => {
    if (key == 'documents') {
      const documentsValue: any = {
        PageIndex: 1,
        RecordPerPage: 50,
        CompanyId: Id,
        DocumentStatusId: 1,
        ClientId: id,
        Year: year,
        Month: month,
      };
      (async () => {
        try {
          const apires =
            await AssignedClientsApiService().getAssignedClientPendingDocumentList(
              documentsValue,
            );
          setDocument(apires);
        } catch (error) {
          console.error('Error fetching documents:', error);
        }
      })();
    } else if (key == 'Requested') {
      const requestdocuments: any = {
        CompanyId: Id,
        PageIndex: 1,
        RecordPerPage: 10,
        DocumentStatusId: 1, // for requested
        Year: year,
        Month: month,
        ClientId: id,
      };
      // AssignedClientsApiService().adminRequestDocumentList(requestdocuments);
      (async () => {
        try {
          const apires =
            await AssignedClientsApiService().adminRequestDocumentList(
              requestdocuments,
            );
          setRequestDocument(apires);
        } catch (error) {
          console.error('Error fetching documents:', error);
        }
      })();
    } else if (key == 'Rejected') {
      const rejectDocuments: any = {
        CompanyId: Id,
        DocumentStatusId: 3,
        Year: year,
        Month: month,
        ClientId: id,
      };
      AssignedClientsApiService().getAssignedClientPendingDocumentList(
        rejectDocuments,
      );
    } else if (key == 'Attention') {
      const attentionDocuments = {
        CompanyId: Id,
        PageIndex: 1,
        RecordPerPage: 10,
        Year: year,
        Month: month,
        ClientId: id,
      };
      AssignedClientsApiService().getAttentionDocument(attentionDocuments);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <div role='button' onClick={() => handleClick('documents')}>
          <Badge count={countValue?.pendingDocument}>Documents</Badge>
        </div>
      ),
      children: (
        <DocumentView
          setAddCommentsModal={setAddCommentsModal}
          setCommentsInfo={setCommentsInfo}
          setDocumentUploadModal={setDocumentUploadModal}
          setMoveToModal={setMoveToModal}
          setDocumentsInfo={setDocumentsInfo}
          movetoModal={movetoModal}
          documentInfo={documentInfo}
          searchName={searchName}
          year={year}
          month={month}
          getDocumentsCount={getDocumentsCount}
          finishedValue={finishedValue}
          initilaMarkFinished={initilaMarkFinished}
          setSubCategoryData={setSubCategoryData}
          subCategoryData={subCategoryData}
          documentCheck={documentCheck}
          handleClick={handleClick}
          secondCategoryData={secondCategoryData}
          setSecondCategoryData={setSecondCategoryData}
        />
      ),
    },
    {
      key: '2',
      label: (
        <div role='button' onClick={() => handleClick('Requested')}>
          <Badge count={countValue?.requestedPendingDocument}>
            Requested Documents
          </Badge>
        </div>
      ),
      children: (
        <RequestDocumentView
          clientId={clientId}
          setAddCommentsModal={setAddCommentsModal}
          setCommentsInfo={setCommentsInfo}
          setMoveToModal={setMoveToModal}
          setDocumentsInfo={setDocumentsInfo}
          year={year}
          month={month}
          searchName={searchName}
          // selectedValue={selectedValue}
          // selectFilterValue={selectFilterValue}
          getDocumentsCount={getDocumentsCount}
          initilaMarkFinished={initilaMarkFinished}
          finishedValue={finishedValue}
          requestdocument={requestdocument}
          handleClick={handleClick}
        />
      ),
    },
    {
      key: '3',
      label: (
        <div role='button' onClick={() => handleClick('Rejected')}>
          <Badge count={countValue?.rejectedDocument}>Rejected Documents</Badge>
        </div>
      ),
      children: (
        <RejectDocumentList
          year={year}
          month={month}
          searchName={searchName}
          activeTabValue={activeTabValue}
          getDocumentsCount={getDocumentsCount}
          // selectedValue={selectedValue}
        />
      ),
    },
    {
      key: '4',
      label: (
        <div role='button' onClick={() => handleClick('Attention')}>
          <Badge count={countValue?.attentionDocument}>
            Attention Documents
          </Badge>
        </div>
      ),
      children: (
        <AttentionDocumentView
          year={year}
          month={month}
          searchName={searchName}
          setAddCommentsModal={setAddCommentsModal}
          setCommentsInfo={setCommentsInfo}
          setCommentsMarkInfo={setCommentsMarkInfo}
          getDocumentsCount={getDocumentsCount}
          // selectedValue={selectedValue}
        />
      ),
    },
    {
      key: '5',
      label: <Badge>Management Report</Badge>,
      children: (
        <ManagementReportView
          year={year}
          month={month}
          searchName={searchName}
          activeTabValue={activeTabValue}
        />
      ),
    },
  ];
  const handleGnerateLink = () => {
    const payloadGenerateLink: any = {
      companyId: Id,
      clientId: id,
    };
    generateUniversalLink(payloadGenerateLink, {
      onSuccess: (data: any) => {
        if (data?.rs == 1) {
          message.success('Success');
        }
        getUniversalLinkData();
      },
    });
  };
  const handleTabChange = (key: string) => {
    setActiveTab(key);
    localStorage.setItem('activeTab', key); // Save active tab to local storage
    const searchParams1 = new URLSearchParams(location.search);
    if (key) {
      searchParams1.set('activeTab', key); // Set activeTab in URL
    } else {
      searchParams1.delete('activeTab'); // Remove activeTab from URL if empty
    }
    naivate(`${location.pathname}?${searchParams1.toString()}`); // Update URL without refreshing
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };
  const handleCopyLink = () => {
    const universalLink =
      getValueLink?.rc?.[0]?.universalLink || 'No link available';

    if (universalLink) {
      navigator.clipboard
        .writeText(universalLink)
        .then(() => {
          message.success('Link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Error copying link:', err);
          message.error('Failed to copy link.');
        });
    } else {
      message.warning('No universal link to copy.');
    }
  };
  useEffect(() => {
    getDocumentsCount();
    getUniversalLinkData();
    initail();
  }, []);
  useEffect(() => {
    if (activeTabValue) {
      setActiveTab(activeTabValue);
    }
  }, []);
  useEffect(() => {
    initilaMarkFinished();
  }, [year, month]);
  useEffect(() => {
    if (apiCountValue) {
      const newCount = {
        pendingDocument: apiCountValue?.rc[0]?.pendingDocument || 0,
        requestedPendingDocument:
          apiCountValue?.rc[0]?.requestedPendingDocument || 0,
        rejectedDocument: apiCountValue?.rc[0]?.rejectedDocument || 0,
        attentionDocument: apiCountValue?.rc[0]?.attentionDocument || 0,
      };
      if (newCount.pendingDocument > countValue.pendingDocument) {
        handleClick('documents');
      }
      //  else if (newCount.pendingDocument === 0 && countValue.pendingDocument === 0 && newCount.pendingDocument > 0) {
      //   handleClick('documents');
      // }
      else if (
        newCount.requestedPendingDocument > countValue.requestedPendingDocument
      ) {
        handleClick('Requested');
      }
      // Only update state if newCount is different from countValue
      if (
        newCount.pendingDocument !== countValue.pendingDocument ||
        newCount.requestedPendingDocument !==
          countValue.requestedPendingDocument ||
        newCount.rejectedDocument !== countValue.rejectedDocument ||
        newCount.attentionDocument !== countValue.attentionDocument
      ) {
        setCountValue(newCount);
      }
    }
  }, [apiCountValue, countValue]);
  useEffect(() => {
    getDocumentsCount();
    const interval = setInterval(() => {
      getDocumentsCount();
    }, 10000); // 10000ms = 10 seconds

    return () => clearInterval(interval);
  }, [year, month]);

  return (
    <Fragment>
      <Row align='top' className='headbx hdwthicn'>
        <Col span={3} className='hd_media bxicon'>
          {screens.lg ? (
            <Image
              src={
                clientData?.rc?.[0]?.clientImage ||
                'https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg'
              }
            />
          ) : (
            <ChevronLeft
              onClick={() => naivate('/assign-client')}
              className='callbackarrow'
            />
            // <ArrowLeft  />
          )}
        </Col>

        <Col span={21} className='hd_lbl'>
          <Title onClick={() => naivate('/assign-client')} level={4}>
            {clientData?.rc?.[0]?.clientName}
          </Title>
          <Typography className='tg_sts'>
            <dt>{clientData?.rc?.[0]?.businessName}</dt>
            <dt
              className='status'
              style={{
                background: clientData?.rc?.[0]?.status ? '#D7E8D3' : '#D4EDDA',
                color: clientData?.rc?.[0]?.status ? '#61AE41' : '#721C24',
              }}
            >
              {clientData?.rc?.[0]?.status == true ? 'Active' : 'InActive'}
            </dt>
          </Typography>

          <Typography className='tg_dte'>
            <dt>Last Update :</dt>
            <dt>{formattedDate}</dt>
          </Typography>

          <Typography className='right_btn'>
            <Button
              className='btn '
              type='primary'
              onClick={() => {
                naivate(
                  `${getRoute(ROUTES.ASSIGNED_EDIT, Id)}?temp_clie=${id}`,
                );
              }}
            >
              <EditOutlined /> Edit
            </Button>
            {getValueLink?.rc?.length ? (
              <Button
                className='btn ml-3'
                type='primary'
                onClick={handleGnerateLink}
                loading={isLoading}
              >
                Update & Generatelink
              </Button>
            ) : (
              <Button
                className='ml-3 border-0'
                type='primary'
                onClick={handleGnerateLink}
                loading={isLoading}
              >
                Send & Generatelink
              </Button>
            )}
            {getValueLink?.rc?.length > 0 && (
              <Button
                className='btn ml-3'
                style={{
                  color: '#fff',
                  background: '#223666',
                  borderRadius: '5px',
                }}
              >
                <CopyOutlined onClick={handleCopyLink} />
                Copy GenerateLink
              </Button>
            )}
          </Typography>
        </Col>
      </Row>

      <Row className='card'>
        <Col span={24}>
          <Row className='crd_head acbx'>
            <Col span={12}>
              <Title level={4}>Documents</Title>
            </Col>
            <Col span={12}>
              <Flex align='flex-end' justify='end' className='srchrbx'>
                <Form.Item>
                  <Input
                    className='form-control'
                    placeholder='Search by Name'
                    value={searchName} // Controlled input value
                    onChange={handleSearchChange}
                  />
                </Form.Item>
                <Space direction='vertical' className=''>
                  <DatePicker
                    className='form-control'
                    picker='month'
                    value={selectedDate} // Controlled value
                    defaultValue={dayjs()} // Default to current month/year
                    onChange={handleDateChange as any}
                    disabledDate={disabledDate}
                    allowClear={false}
                    format='MMMM YYYY' // Display as "Month Name YYYY"
                  />
                </Space>
              </Flex>
            </Col>
          </Row>

          <Col span={24} className='cstm_tabs'>
            <Tabs
              className='tsbrow'
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
            />
          </Col>
        </Col>
      </Row>
      {addCommentsModal && (
        <CommentsItemView
          onClose={() => setAddCommentsModal(false)}
          commentsInfo={commentsInfo}
          commentsMarkInfo={commentsMarkInfo}
          getDocumentsCount={getDocumentsCount}
          setSubCategoryData={setSubCategoryData}
          secondCategoryData={secondCategoryData}
          setSecondCategoryData={setSecondCategoryData}
        />
      )}
      {documentUploadModal && (
        <AddDocumentsModal
          onClose={() => setDocumentUploadModal(false)}
          // selectedValue={selectedValue}
          year={year}
          month={month}
        />
      )}
    </Fragment>
  );
};
