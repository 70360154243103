import { Col, Grid, Image, message, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { NewClientsApiService } from 'features/new-clients/api/new-clients-api.service';
import { AddActivateModal } from 'features/new-clients/components/modal/add-activate-view';
import { ApproveRejectModal } from 'features/new-clients/components/modal/approve-reject';
import { useGetClientsDetails } from 'features/new-clients/use-cases/get-client';
import { ChevronLeft } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ClientsViewActivateInfo } from './clients-activate-info';
import { ReminderClientsModal } from './reminder-clientsitem-modal';

const { Title } = Typography;

type ClientsProps = {
  Id: string;
  onSuccess: () => void;
};

export const ClientsView = ({ Id, onSuccess }: ClientsProps) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const naivate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [ApproveRjectModal, setApproveRjectModal] = useState(false);
  const [documentDetail, setDocumentDetails] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('clsid');
  const { data: Clientsinit } = useGetClientsDetails(`${id}/${Id}` as any);
  const [documentList, setDocumentList] = useState<any>('');
  const intialFetch = async () => {
    try {
      const apiRes = await NewClientsApiService().getDocument(Id as any);
      setDocumentList(apiRes as any);
    } catch (error: any) {
      message.error(error);
    }
  };
  useEffect(() => {
    intialFetch();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Row align='top' className='headbx hdwthicn'>
            <Col span={3} className='hd_media bxicon'>
              {screens.lg ? (
                <Image
                  alt='Client Image'
                  src={
                    Clientsinit?.rc[0]?.clientImage ||
                    'https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg'
                  }
                  fallback='https://via.placeholder.com/150' // Default image if the clientImage is missing
                />
              ) : (
                <ChevronLeft
                  onClick={() => naivate('/new-clients')}
                  className='callbackarrow'
                />
                // <ArrowLeft  />
              )}
            </Col>
            <Col span={21} className='hd_lbl'>
              <Title level={4}>{Clientsinit?.rc[0]?.clientName}</Title>
              <Typography className='tg_sts'>
                <dt>{Clientsinit?.rc[0]?.clientName}</dt>
                <dt
                  className='status'
                  style={{
                    background: Clientsinit?.rc[0]?.status
                      ? '#F8D7DA'
                      : '#D4EDDA',
                    color: Clientsinit?.rc[0]?.status ? '#721C24' : '#155724',
                  }}
                >
                  {Clientsinit?.rc[0]?.status ? 'Inactive' : 'Active'}
                </dt>
              </Typography>
              <Typography className='tg_dte'>
                <dt>Last Update:</dt>
                <dt>
                  {dayjs(Clientsinit?.rc[0]?.LastUpdated).format(
                    'DD MMM YYYY | hh:mm A',
                  )}
                </dt>
              </Typography>
            </Col>
          </Row>

          <Row className='card'>
            <Col span={24}>
              <ClientsViewActivateInfo
                setShowModal={setShowModal}
                setApproveRjectModal={setApproveRjectModal}
                setDocumentDetails={setDocumentDetails}
                onClose={onSuccess}
                Clientsdata={Clientsinit}
                documentList={documentList}
                // documentDetail={documentDetail}
                intialFetch={intialFetch}
                setReminderModal={setReminderModal}
              />
            </Col>
          </Row>
        </Col>

        {showModal && (
          <AddActivateModal
            onClose={() => setShowModal(false)}
            clientsdata={Clientsinit}
          />
        )}
        {ApproveRjectModal && (
          <ApproveRejectModal
            onClose={() => setApproveRjectModal(false)}
            documentDetail={documentDetail}
            Clientsdata={Clientsinit}
            intialFetch={intialFetch}
          />
        )}

        {reminderModal && (
          <ReminderClientsModal
            onClose={() => setReminderModal(false)}
            reminderId={id}
          />
        )}
      </Row>
    </Fragment>
  );
};
