import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AssignedClientsApiService } from 'features/assigned-clients/api/assign-clients-api.service';

const { uploadImageDocument } = AssignedClientsApiService();

export const useUploadImage = () => {
  const { message } = App.useApp();

  return useMutation<
    { rs: number },
    unknown,
    { id: string; formData: FormData }
  >(({ id, formData }) => uploadImageDocument(id, formData), {
    onError: (error) => {
      const uiMessage = handleError(error);
      message.error(uiMessage);
    },
    onSuccess: (data) => {
      if (data.rs === 1) {
        message.success('File Uploaded successfully');
      }
    },
  });
};
