import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { UserApiService } from 'features/users/api/user-api.service';
import { queryClient } from 'providers';

import { userQueryKeys } from './query-keys';
const { ProfileUpdate } = UserApiService();

export const useProfileUpdate = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return ProfileUpdate(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.all,
        });
      },
    },
  );
};
