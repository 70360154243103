import { Form, Grid, Input, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Loader, Modal, Result404 } from 'components';
import {
  reminderFormField,
  reminderFormValue,
} from 'features/new-clients/components/clients-form/type';
import { useCreateReminder } from 'features/new-clients/use-cases/create-reminder';
import { useGetClientsDetails } from 'features/new-clients/use-cases/get-client';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
const { useBreakpoint } = Grid;
export const ReminderViewModal = ({
  onClose,
  reminderId,
}: {
  onClose: () => void;
  reminderId: any;
}) => {
  const formName = 'add-category-form';
  const screens = useBreakpoint();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('clsid');
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<reminderFormValue>();
  const { isLoading, mutate } = useCreateReminder();
  const userQueryResult: any = useGetClientsDetails(
    `${ids || `${reminderId?.Id}/${reminderId?.CompanyId}`}`,
    {
      cacheTime: 0,
    },
  );
  const {
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
    data: Reminderdata,
    isError,
  } = userQueryResult;
  const isLoadingReminder = {
    loading: isLoadingGet || isFetchingGet,
    isLoadingGet,
  };
  useEffect(() => {
    if (Reminderdata) {
      form.setFieldsValue({
        [reminderFormField.Email]: Reminderdata?.rc[0]?.email,
        [reminderFormField.Message]: undefined,
      });
    }
  }, [Reminderdata, form]);
  const handleUpdate = (formData: reminderFormValue) => {
    const payload: any = {
      CompanyId: reminderId?.CompanyId,
      CustomerId: reminderId?.Id,
      Email: formData?.Email,
      Message: formData?.Message,
    };
    mutate(payload, { onSuccess: onClose });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Send Reminder'}
        onCancel={onClose}
        closeIcon={''}
        getContainer={false}
        onOk={form.submit}
        okText={'Sand'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '500px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        {isLoadingGet ? (
          <Loader />
        ) : isError ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoadingReminder.isLoadingGet}>
            <Form<reminderFormValue>
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              // initialValues={getInitialValues}
              onFinish={handleUpdate}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading}
            >
              <Form.Item label='Email' name={reminderFormField.Email}>
                <Input placeholder='Enter Email' disabled />
              </Form.Item>
              <Form.Item
                name={reminderFormField.Message}
                label='Message'
                rules={[{ required: true, message: 'Please Enter message' }]}
              >
                <TextArea placeholder='Enter message' />
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
