import dayjs from 'dayjs';
import { NoData } from 'features/no-data/no-data';
import { UsersTopBar } from 'features/users/components/top-bar/users-top-bar';
import { UsersTable } from 'features/users/components/users/users-table';
import {
  UserFilterFormsValues,
  UserFiltersFormValuesPaginated,
} from 'features/users/components/users-filter-form/types';
import { useGetUsersList } from 'features/users/use-cases/get-users';
import { useUserContext } from 'features/users/user-context';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';

const getInitialFilterValues: UserFilterFormsValues = {
  Name: undefined,
  RoleName: undefined,
  Email: undefined,
  IsActive: undefined,
  CreatedDate: undefined,
  date: [undefined, undefined],
};

export const AdminUsersView = () => {
  const { menuItem }: any = useUserContext();
  const { params, updateParams, resetPage } =
    usePaginationParams<UserFiltersFormValuesPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      ...getInitialFilterValues,
    });
  // const preparePayload = (data: any) => {
  //   return {
  //     ...data, // Spread other existing properties in `data`
  //     CreatedDate: data?.CreatedDate
  //       ? dayjs(data.CreatedDate).format('YYYY-MM-DD')
  //       : null,
  //   };
  // };
  const preparePayload = (paramsw: any) => {
    const createdAtFrom = paramsw.date?.[0];
    const createdAtTo = paramsw.date?.[1];
    
return {
      ...omit(params, ['date']),
      CreatedDate: paramsw?.CreatedDate
        ? dayjs(paramsw.CreatedDate).format('YYYY-MM-DD')
        : null,
      LastUpdateFrom: createdAtFrom
        ? formatDate({
            date: dayjs(createdAtFrom).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      LastUpdateTo: createdAtTo
        ? formatDate({
            date: dayjs(createdAtTo).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
    };
  };
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const getUsersListQueryResponse = useGetUsersList(
    preparePayload(params), // Directly pass `params`
    {
      retry: false, // Disable retry if the query fails
    },
  );
  const handleFiltersChange = (values: UserFilterFormsValues) => {
    updateParams(values);
    resetPage();
  };
  if (menuItem?.rc?.length === 0) {
    return <NoData />;
  }

  return (
    <>
      <UsersTopBar
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
      />
      <UsersTable
        isUsersListLoading={getUsersListQueryResponse.isFetching}
        usersList={getUsersListQueryResponse?.data?.rc || []}
        itemsCount={getUsersListQueryResponse.data?.total || 0}
        tableParams={params}
        updateParams={updateParams}
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
      />
      <Outlet />
    </>
  );
};
