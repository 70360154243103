import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Grid,
  Image,
  Input,
  message,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Modal } from 'components';
import { useRequestManagementUpload } from 'features/assigned-clients/use-cases/create-report';
import { useUploadImage } from 'features/assigned-clients/use-cases/upload-image';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { convertFileSize, validateFileBeforeUpload } from 'routes/utils';

const { useBreakpoint } = Grid;

export const AddReportView = ({
  onClose,
  refetch,
  year,
  month,
  initilaMarkFinished,
}: {
  onClose: () => void;
  refetch: any;
  year: any;
  month: any;
  initilaMarkFinished: any;
}) => {
  const { id } = useParams();
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const { isLoading: reportLoading, mutate: mutateReport } =
    useRequestManagementUpload();
  const { isLoading: imageLoading, mutate: uploadImage } = useUploadImage();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [blobUrl, setBlobUrl] = useState<string>(''); // Store the uploaded blob URL
  const [filenameUrl, setFileNameUrl] = useState('');
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false); // Loading state for image upload
  const [form] = Form.useForm();

  const handleImageUpload = (file: UploadFile) => {
    const formData = new FormData();
    formData.append('file', file as any);

    setIsImageUploading(true); // Set uploading state to true

    uploadImage(
      { id: id as string, formData }, // Pass id and file as payload
      {
        onSuccess: (response: any) => {
          setBlobUrl(response.blobUrl); // Save blob URL
          setFileNameUrl(response.fileName);
          message.success('File uploaded successfully');
          setIsImageUploading(false); // Reset uploading state
        },
        onError: () => {
          message.error('file upload failed');
          setIsImageUploading(false); // Reset uploading state
        },
      },
    );
  };

  const handleUpdate = (formValues: any) => {
    if (!blobUrl) {
      return message.error('Please upload an file before saving');
    }
    const formData = new FormData();
    formData.append('DocumentName', formValues.DocumentName);
    formData.append('CompanyId', id as any);
    formData.append('Note', formValues.Note);
    formData.append('Month', month);
    formData.append('Year', year);

    // Include the uploaded file blob URL in the form data
    formData.append('file', blobUrl);

    // Get the filename and filesize from the uploaded file
    const uploadedFile = fileList[0]?.originFileObj; // Assuming fileList contains the uploaded file

    if (uploadedFile) {
      // Get the filename and filesize from the file object
      const fileSize = uploadedFile.size;
      // Append filename and filesize to FormData
      formData.append('FileName', filenameUrl);
      formData.append('FileSize', convertFileSize(fileSize)); // FileSize is typically a number, so convert it to string
    }

    mutateReport(formData, {
      onSuccess: () => {
        onClose();
        refetch();
        initilaMarkFinished();
      },
    });
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = isImageUploading ? (
    <div>
      <Spin indicator={<LoadingOutlined />} />
      <div style={{ marginTop: 8 }}>Uploading</div>
    </div>
  ) : (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    const isValid = validateFileBeforeUpload(file);
    if (isValid) {
      handleImageUpload(file); // Call handleImageUpload only if valid
    }

    return isValid; // If false, upload will be prevented
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        title={<span className='font-semibold'>Upload Documents</span>}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Upload'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          className: 'btn cstmfull',
          form: formName,
          loading: reportLoading,
          disabled: imageLoading || isImageUploading, // Disable Save button while uploading
        }}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          onFinish={handleUpdate}
        >
          <Form.Item
            label='Report Name'
            name='DocumentName'
            rules={[{ required: true, message: 'Please enter report name' }]}
          >
            <Input placeholder='Enter Report Name' />
          </Form.Item>
          <Form.Item name={'file'} label='Upload Document'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Upload
                listType='picture-card'
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                // beforeUpload={(file) => {
                //   handleImageUpload(file as UploadFile);
                //   return false; // Prevent auto upload
                // }}
                beforeUpload={beforeUpload}
                maxCount={1}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              {isImageUploading && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                >
                  <Spin size='large' />
                </div>
              )}
            </div>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
          <Form.Item
            label='Notes'
            name={'Note'}
            rules={[{ required: true, message: 'Please enter notes' }]}
          >
            <TextArea placeholder='Enter notes' />
          </Form.Item>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
