import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { newClients } from 'types/new-clients';
export interface ClientsListParams extends PaginatedRequestParams {
  Name?: string;
  Email?: string;
  PhoneNumber?: string;
  retry?: boolean;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}
export type fetchFalg = {
  Flag: string;
};
export type CreateReminder = {
  CustomerId: string;
  Email: string;
  Message: string;
};
export type updateEmail = {
  CustomerId: string;
  Email: string;
};
export type NewClientsResponse = PaginatedResponse<newClients>;

export const NewClientsApiService = () => {
  const createClients = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Client/CreateClient`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const clientsApproveReject = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Admin/ApproveRejectRequiredDocument`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const updateMail = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Client/UpdateEmail`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const activateClients = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Admin/ClientActivated`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const createReminder = async (
    payload: CreateReminder,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<NewClientsResponse>({
      ...callConfig,
      endpoint: `/Client/ReminderMail`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getClients = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<NewClientsResponse>({
      ...callConfig,
      endpoint: `/Client/GetClientList`,
      params,
    });

    return response.data;
  };
  const dropDownByFlag = async (
    Flag: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<NewClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Dropdown/Get?Flag=${Flag}`,
    });

    return response;
  };
  const getCustomizeAccess = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<NewClientsResponse>({
      ...callConfig,
      endpoint: `/Client/GetClientServices`,
      params,
    });

    return response.data;
  };

  const GetClientsById = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<NewClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/GetCompanyDetail/${id}`,
    });

    return response.data;
  };

  const getDocument = async (
    id: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<NewClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/GetClientRequiredDocumentList?CompanyId=${id}`,
    });

    return response.data;
  };
  const dropDownClientsFlag = async (
    Flag: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<NewClientsResponse>({
      ...callConfig,
      endpoint: `${baseURL}/Dropdown/Get?Flag=${Flag}`,
    });

    return response;
  };

  const updateClients = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/Client/UpdateCompany`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const GetCategory = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/Master/GetCategory`,
      params,
    });

    return response.data;
  };
  const GetSubCategory = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/Master/GetSubCategory`,
      params,
    });

    return response.data;
  };
  const GetClass = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const baseURL = process.env.REACT_APP_PARTNERPLUS_API_CLIENT_BASE_URL;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/Master/GetClass`,
      params,
    });

    return response.data;
  };

  return {
    getClients,
    createClients,
    dropDownByFlag,
    getCustomizeAccess,
    GetClientsById,
    createReminder,
    updateMail,
    activateClients,
    clientsApproveReject,
    getDocument,
    dropDownClientsFlag,
    updateClients,
    GetCategory,
    GetSubCategory,
    GetClass,
  };
};
