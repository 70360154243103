import {
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileExcelOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import { Button, Image, Modal, notification, Tooltip } from 'antd';
import React, { useState } from 'react';

interface FileViewerProps {
  documentPath: string;
}

const FileViewer: React.FC<FileViewerProps> = ({ documentPath }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const getFileExtension = (url: string): string | null => {
    const match = url?.match(/\.(\w+)(?:\?|$)/);

    return match ? match[1].toLowerCase() : null;
  };

  const handleOpenModal = () => {
    // Ensure iframe source is set before showing the modal
    const uniqueUrl = `${documentPath}?t=${new Date().getTime()}`;
    setIframeSrc(
      `https://docs.google.com/gview?url=${uniqueUrl}&embedded=true`,
    );
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setIframeSrc(null); // Reset iframe source to force reload on next open
  };

  const handleDownload = () => {
    const downloadUrl = `${documentPath}?t=${new Date().getTime()}`;
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = ''; // Let the browser handle file naming
    a.click();

    notification.info({
      message: 'Download started',
      description: 'The file is being downloaded.',
      duration: 3,
    });
  };

  const extension = getFileExtension(documentPath);

  if (!extension) {
    return <div>Invalid file URL</div>;
  }

  if (['jpg', 'jpeg', 'png', 'gif', 'pmg'].includes(extension)) {
    return (
      <Image src={`${documentPath}?t=${new Date().getTime()}`} alt='Document' />
    );
  }

  if (
    ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv', 'pdf'].includes(
      extension,
    )
  ) {
    return (
      <>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered ? (
            <figure className='mtlybtnbx'>
              <Tooltip title='Preview'>
                <Button
                  type='link'
                  onClick={handleOpenModal}
                  icon={<EyeOutlined />}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </Tooltip>
              <Tooltip title='Download'>
                <Button
                  type='link'
                  onClick={handleDownload}
                  icon={<DownloadOutlined />}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </Tooltip>
            </figure>
          ) : (
            <></>
          )}
          <FileExcelOutlined style={{ fontSize: '32px', color: '#52c41a' }} />
        </div>

        <Modal
          title='Document Viewer'
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          closeIcon={<CloseOutlined />}
          width='80%'
          style={{ top: 20, maxWidth: '500px' }}
        >
          {iframeSrc && (
            <iframe
              src={iframeSrc}
              width='100%'
              height='450px'
              style={{ border: 'none' }}
              title='Document Viewer'
            />
          )}
        </Modal>
      </>
    );
  }
  if (extension === 'zip') {
    return (
      <div>
        <Tooltip title='Download'>
          <Button
            type='link'
            onClick={handleDownload}
            icon={<FileZipOutlined />}
            style={{ display: 'flex', alignItems: 'center' }}
          />
        </Tooltip>
      </div>
    );
  }
  
return (
    <Button
      type='link'
      onClick={handleDownload}
      icon={<DownloadOutlined />}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      Download File
    </Button>
  );
};

export default FileViewer;
