import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AssignClientsResponse,
  AssignedClientPendingDocumentList,
  AssignedClientsApiService,
} from 'features/assigned-clients/api/assign-clients-api.service';
import qs from 'qs';

import { AssignDetailClientsQueryKeys } from './query-keys';
const { getAssignedClientPendingDocumentList } = AssignedClientsApiService();

const getDocumentCategoryQuery = (
  params: AssignedClientPendingDocumentList,
  options?: UseQueryOptions<AssignClientsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<AssignClientsResponse>;
  options?: UseQueryOptions<AssignClientsResponse>;
} => ({
  queryKey: AssignDetailClientsQueryKeys.all,
  queryFn: ({ signal }) =>
    getAssignedClientPendingDocumentList(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetPendingDocumentList = (
  params: any,
  options?: UseQueryOptions<AssignClientsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<AssignClientsResponse, Error>(
    getDocumentCategoryQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      staleTime: Infinity, // Prevent the data from being considered stale
      cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
      refetchOnWindowFocus: false, // Don't refetch when the window is focused
      refetchInterval: false, // Don't refetch periodically
      enabled: false, // Prevent automatic query firing initially
      ...options,
    }),
  );
};
