import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Grid,
  Image,
  Input,
  message,
  Row,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useChangeImageProfile } from 'features/users/use-cases/upload-image-profile';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailVerifyOtpViewModal } from './otpVerify';
import { ProfileField, ProfileFormValues } from './types';

const { useBreakpoint } = Grid;

type ProfileFormProps = {
  handleSubmit: (formValues: ProfileFormValues) => void;
  user: any;
  isLoading: boolean;
  fileList: any;
  setFileList: any;
  previewImage: any;
  setPreviewImage: any;
  previewOpen: any;
  setPreviewOpen: any;
  setIsProfileFormDirty: Dispatch<SetStateAction<boolean>>;
};

export const ProfileForm = ({
  handleSubmit,
  user,
  isLoading,
  fileList,
  setFileList,
  previewImage,
  setPreviewImage,
  previewOpen,
  setPreviewOpen,
  setIsProfileFormDirty,
}: ProfileFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [emailUpdate, setEmailUpdate] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const { mutate: ImageProfile, isLoading: ImageUpload } =
    useChangeImageProfile();

  const getInitialValues = {
    Name: user.Name,
    [ProfileField.PhoneNumber]: user.phone_number,
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleOnFinish = (formValues: ProfileFormValues) => {
    setIsProfileFormDirty(false);
    handleSubmit(formValues);
  };

  const handleUpdate = (formValue: any) => {
    setShowOtp(true);
    setEmailUpdate(formValue);
  };

  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append('UserId', user?.Id);
    formData.append('file', file);

    ImageProfile(formData, {
      onSuccess: (data: any) => {
        if (data?.rs === 1) {
          message.success('Profile image updated successfully');
        } else {
          message.error(data?.rm || 'Failed to upload image');
        }
      },
      onError: () => {
        message.error('Image upload failed. Please try again.');
      },
    });
  };

  const handleImageChange: UploadProps['onChange'] = ({
    fileList: newFileList,
    file,
  }) => {
    setFileList(newFileList);
    if (file.status !== 'removed') {
      handleImageUpload(file as any);
    }
  };

  useEffect(() => {
    if (user?.ProfileImage) {
      setFileList([
        {
          uid: '-1',
          name: 'ProfileImage',
          status: 'done',
          url: user.ProfileImage,
        },
      ]);
    }
  }, [user]);
  useEffect(() => {
    if (user?.Email) {
      // Set the Name field value when user.Name is available
      form.setFieldsValue({ Email: user.Email });
    }
  }, [user, form]);
  
return (
    <>
      <Form
        id='profile-form'
        name='profile-form'
        form={form}
        layout={'vertical'}
        initialValues={getInitialValues}
        onFinish={handleOnFinish}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        disabled={isLoading}
        validateTrigger={validateTrigger}
      >
        <Row>
          <Col span={5} style={{ maxWidth: '225px' }}>
            <Form.Item label={''} name={'ProfileImage'}>
              <div
                style={{
                  width: '200px',
                  position: 'relative',
                  display: 'inline-block',
                  border: isLoading ? '0px solid #1890ff' : '0px solid #d9d9d9', // Show border with color based on loading state
                  padding: '0px',
                }}
              >
                <Upload
                  className='uplddocbx'
                  listType='picture-card'
                  name='file'
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleImageChange}
                  beforeUpload={() => false} // Prevent auto-upload
                  maxCount={1}
                >
                  {fileList?.length >= 1 ? null : (
                    <div className='upldbtn'>{uploadButton}</div>
                  )}
                </Upload>

                {/* Loader Spinner Inside the Border when Loading */}
                {ImageUpload && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                  >
                    <Spin size='large' />
                  </div>
                )}
              </div>

              {/* Preview Uploaded Image */}
              {previewImage && (
                <Image
                  className='uplddocbx'
                  wrapperStyle={{ display: 'none' }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
            </Form.Item>
          </Col>

          <Col
            className='mt-6'
            span={screens.lg ? 7 : 'auto'}
            offset={screens.lg ? 0 : 0}
          >
            {/* First Name Field */}

            <div className='relative'>
              <Form.Item<ProfileField>
                label={'Name'}
                name={'Name'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  style={{ fontSize: '14px', minHeight: '40px' }}
                  placeholder={t(
                    'accountSettings.formFields.firstName.placeholder',
                  )}
                  size='large'
                />
              </Form.Item>
              <Button
                className='absolute btn'
                style={{
                  backgroundColor: '#223666',
                  border: 'none',
                  fontSize: '9px',
                  right: '10px',
                  bottom: '9px',
                  padding: '4px 8px',
                }}
                type='primary'
                disabled={isLoading}
                htmlType='submit'
              >
                Change Name
                {/* {t('accountSettings.buttons.saveChanges')} */}
              </Button>
            </div>

            {/* Email Field */}

            <div className='relative'>
              <Form.Item<ProfileField>
                label={t('accountSettings.formFields.email.label')}
                name={ProfileField.Email}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  style={{ fontSize: '14px', minHeight: '40px' }}
                  placeholder={t(
                    'accountSettings.formFields.email.placeholder',
                  )}
                />
              </Form.Item>
              <Button
                type='primary'
                className='absolute btn'
                style={{
                  backgroundColor: '#223666',
                  border: 'none',
                  fontSize: '9px',
                  right: '10px',
                  bottom: '9px',
                  padding: '4px 8px',
                }}
                onClick={() => {
                  const email = form.getFieldValue(ProfileField.Email);
                  handleUpdate({ Email: email });
                }}
              >
                Change Email
              </Button>
            </div>

            {/* Email Verify Button */}
            {/* <Row gutter={8} align='middle' style={{ display: 'flex' }}>
            <Col flex='auto'></Col>
            <Col>
              
            </Col>
          </Row> */}

            {/* Profile Image Upload */}

            {/* Submit Button */}
          </Col>
        </Row>
      </Form>

      {/* OTP Modal */}
      {showOtp && (
        <EmailVerifyOtpViewModal
          onClose={() => setShowOtp(false)}
          user={emailUpdate}
        />
      )}
    </>
  );
};
