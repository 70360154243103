import type { TabsProps } from 'antd';
import { message, Tabs } from 'antd';
import { ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import {
  PasswordForm,
  PasswordFormValues,
  ProfileForm,
} from 'features/account-settings';
import { useUserContext } from 'features/users';
import { useChangePasswordUser } from 'features/users/use-cases/change-password-profile';
import { useProfileUpdate } from 'features/users/use-cases/profile-update';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AccountSettingsView = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [isProfileFormDirty, setIsProfileFormDirty] = useState(false);
  const [isPasswordFormDirty, setIsPasswordFormDirty] = useState(false);
  const userId = localStorage.getItem('userId');
  const { mutate: mutateUpdateUser, isLoading: isLoadingUser } =
    useProfileUpdate();
  const { mutate: mutateUpdatePasswordUser, isLoading: isLoadingUpdateUser } =
    useChangePasswordUser();
  const onChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };
  const handleUpdateProfile = (formData: any) => {
    const payload = {
      Name: formData?.Name,
      UserId: userId,
    };

    mutateUpdateUser(payload, {
      onSuccess: (data: any) => {
        if (data?.rs == 1) {
          message.success('Profile update SuccessFully');
        } else {
          message.error(data?.rm);
        }
      },
    });
  };
  const handleUpdatePassword = (
    formData: PasswordFormValues,
    onSuccess: () => void,
  ) => {
    const payload = { ...formData, UserId: userId };
    mutateUpdatePasswordUser(payload, { onSuccess });
  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `${t('accountSettings.tab.profile')}`,
      children: (
        <>
          <StyledContentBackground className='card'>
            <ProfileForm
              user={user}
              handleSubmit={handleUpdateProfile}
              isLoading={isLoadingUser}
              fileList={fileList}
              setFileList={setFileList}
              previewImage={previewImage}
              setPreviewImage={setPreviewImage}
              previewOpen={previewOpen}
              setPreviewOpen={setPreviewOpen}
              setIsProfileFormDirty={setIsProfileFormDirty}
            />
          </StyledContentBackground>
        </>
      ),
    },
    {
      key: '2',
      label: `${t('accountSettings.tab.password')}`,
      children: (
        <StyledContentBackground>
          <PasswordForm
            handleSubmit={handleUpdatePassword}
            isLoading={isLoadingUpdateUser}
            setIsPasswordFormDirty={setIsPasswordFormDirty}
          />
        </StyledContentBackground>
      ),
    },
  ];

  return (
    <>
      <ContentTopBar title={t('accountSettings.title')} />
      <Tabs
        defaultActiveKey='1'
        activeKey={activeTab}
        items={items}
        onChange={onChange}
      />
      <RouteLeavingGuard blocked={isProfileFormDirty || isPasswordFormDirty} />
    </>
  );
};
