import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Dropdown,
  Flex,
  Image,
  Menu,
  message,
  Modal,
  Pagination,
  Row,
  Typography,
} from 'antd';
// import { useClientDocumentApproveReject } from 'features/assigned-clients/use-cases/approve-reject-document';
import { useDeleteDocuments } from 'features/assigned-clients/use-cases/delete.documents';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Fragment, useEffect, useState } from 'react';
import { formatUtcToLocal, getFileExtension } from 'routes/utils';

import { ApproveLocationChangeViewModal } from './approve-location-change';
import { RequestedRejectDocumentsModal } from './reject-modal';
const { Title } = Typography;
export const RejectedPendingDocuments = ({
  pendingDocument,
  // companyId,
  reFetchPendingDocument,
  setAddCommentsModal,
  setCommentsInfo,
  setMoveToModal,
  setDocumentsInfo,
  refetch,
  getDocumentsCount,
  setCurrentPage,
  currentPage,
  itemsPerPage,
  requestdocument,
  handleClick,
}: any) => {
  const [rejectModal, setRejectModal] = useState(false);
  const { mutate } = useDeleteDocuments();
  const [documentDetail, setdocumentDetail] = useState('');
  const [showAppRoveLocation, setShowArrpoveLocation] = useState(false);
  const [itemApprove, setApproveItem] = useState('');
  const [combinedData, setCombinedData] = useState<any[]>([]);
  const onChangeDelete = (valueInfo: any) => {
    const formValue: any = {
      DocumentId: valueInfo?.documentId,
      IsAdmin: true,
    };

    mutate(formValue, {
      onSuccess: () => {
        reFetchPendingDocument(); // Ensure refetch is called to reload data
        refetch();
      },
      onError: (error) => {
        // Optionally handle errors
        console.error('Error deleting:', error);
      },
    });
  };

  const handleMenuClick = ({ key }: any, item: any) => {
    if (key === 'download') {
      message.success('Downloading ' + item.documentName);
      const link = document.createElement('a');
      link.href = item.documentPath; // File path or URL
      link.target = '_blank'; // Open in a new tab
      link.download = item.documentName; // Suggested file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (key === 'preview') {
      // Open the preview in a new tab
      Modal.info({
        title: 'Document View',
        width: '100%',
        icon: null,
        closable: true,
        closeIcon: <CloseOutlined />,
        style: { top: '0px', maxWidth: '500px' },
        content: (
          <div style={{ textAlign: 'start' }}>
            {(() => {
              const extension = getFileExtension(item.documentPath);

              if (['jpg', 'jpeg', 'png', 'gif'].includes(extension!)) {
                // If it's an image, display it directly
                return <Image src={item.documentPath} width='100%' />;
              } else if (
                [
                  'doc',
                  'docx',
                  'xls',
                  'xlsx',
                  'ppt',
                  'pptx',
                  'pdf',
                  'csv',
                ].includes(extension!)
              ) {
                // Use Google Docs Viewer for documents
                return (
                  <iframe
                    src={`https://docs.google.com/gview?url=${item.documentPath}&embedded=true`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              } else {
                return (
                  <iframe
                    src={`https://docs.google.com/gview?url=${item.documentPath}&embedded=true`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              }
            })()}
          </div>
        ),
        okButtonProps: { style: { display: 'none' } },
      });
    } else if (key === 'Add Comment') {
      setAddCommentsModal(true);
      setCommentsInfo(item);
    } else if (key === 'Move To') {
      setMoveToModal(true);
      setDocumentsInfo(item);
    } else if (key === 'Delete') {
      onChangeDelete(item);
    }
  };
  const renderMenu = (item: any) => (
    <Menu
      onClick={({ key }) => handleMenuClick({ key }, item)}
      items={[
        { label: 'Download', key: 'download' },
        { label: 'Preview', key: 'preview' },
        // { label: 'Add Comment', key: 'Add Comment' },
        { label: 'Delete', key: 'Delete' },
      ]}
    />
  );
  // Pagination calculations
  // const totalRecords = pendingDocument?.rc?.[0]?.totalRecords || 0;
  // const paginatedData = pendingDocument?.rc || [];
  useEffect(() => {
    const combineLists = () => {
      const documentCheckArray = Array.isArray(requestdocument?.rc)
        ? requestdocument?.rc
        : [];
      const pendingDocumentArray = Array.isArray(pendingDocument?.rc)
        ? pendingDocument.rc
        : [];
      // Combine and deduplicate lists based on documentId
      const combinedMap = new Map();
      documentCheckArray.forEach((doc: any) =>
        combinedMap.set(doc.documentId, doc),
      );
      pendingDocumentArray.forEach((doc: any) =>
        combinedMap.set(doc.documentId, doc),
      );
      setCombinedData(Array.from(combinedMap.values()));
    };
    combineLists();
  }, [requestdocument, pendingDocument]);
  const totalRecords = combinedData.length;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const paginatedData = combinedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );
  
return (
    <Fragment>
      <Row className='fldrbx'>
        {paginatedData.map((item: any, index: number) => (
          <Card key={index}>
            <Flex>
              <Typography className='meidafolder meida'>
                <FileViewer documentPath={item.documentPath} />
              </Typography>
              <Typography className='cvnu'>
                <Title className='crdttl' level={3}>
                  {item.documentName}

                  <dt className='stsbx'>
                    <dt
                      className={
                        item.documentStatus === 'approved'
                          ? 'approved'
                          : item.documentStatus == 'pending'
                          ? 'pending'
                          : 'rejected'
                      }
                    >
                      {item?.documentStatus == 'Reject'
                        ? 'Document Rejected'
                        : item?.documentStatus}
                    </dt>
                  </dt>

                  <dt className='mnubdrp'>
                    <Dropdown overlay={renderMenu(item)} trigger={['click']}>
                      <MoreOutlined
                        style={{ fontSize: '24px', cursor: 'pointer' }}
                      />
                    </Dropdown>
                  </dt>
                </Title>
                {item.documentSize != null ? (
                  <dt className='tmrbx'>Size : {item.documentSize} </dt>
                ) : (
                  ''
                )}

                <dt className='tmrbx'>
                  Last Update:{' '}
                  {/* {dayjs(item.lastUpdatedDate).format(
                      'DD MMM YYYY | hh:mm A',
                    )} */}
                  {formatUtcToLocal(item.lastUpdatedDate)}
                  {/* 24 Sep, 2024 | 03:11 PM */}
                </dt>

                <dt className='button_group'>
                  {item?.documentStatus !== 'Approved' &&
                    item?.documentStatus !== 'Reject' && (
                      <div>
                        {/* <Popconfirm
                            title='Approve the Document'
                            description='Are you sure want to approve this Document?'
                            onConfirm={() => onChnageApprove(item)}
                            okText='Yes'
                            cancelText='No'
                          > */}
                        <Button
                          className='btn'
                          onClick={() => {
                            setShowArrpoveLocation(true);
                            setApproveItem(item);
                          }}
                        >
                          Approve
                        </Button>
                        {/* </Popconfirm> */}

                        <Button
                          onClick={() => {
                            setRejectModal(true);
                            setdocumentDetail(item);
                          }}
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                </dt>
              </Typography>
            </Flex>
          </Card>
        ))}
      </Row>
      {totalPages > 1 && (
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalRecords}
          onChange={(page) => setCurrentPage(page)}
          style={{ textAlign: 'center', marginTop: '20px' }}
        />
      )}
      {rejectModal && (
        <RequestedRejectDocumentsModal
          documentDetail={documentDetail}
          onClose={() => setRejectModal(false)}
          reFetchPendingDocument={reFetchPendingDocument}
          getDocumentsCount={getDocumentsCount}
          handleClick={handleClick}
        />
      )}
      {showAppRoveLocation && (
        <ApproveLocationChangeViewModal
          onClose={() => setShowArrpoveLocation(false)}
          item={itemApprove}
          reFetchPendingDocument={reFetchPendingDocument}
          handleClick={handleClick}
        />
      )}
    </Fragment>
  );
};
