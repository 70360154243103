import { Anchor, Card, Row } from 'antd';
import lgn from 'assets/images/lgnbg.png';
import styled from 'styled-components';

export const StyledBackground = styled(Row)`
  min-height: 100vh; /* Full height of the viewport */
  padding: 0px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  background-position: center center;
  background-size: cover !important;
  background: #edf0f5 url(${lgn});

  @media (max-width: 900px) {
    padding: 20px;
    background: #fff;
    align-items: flex-start;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  max-width: 450px; /* Optionally limit the maximum width */
  border: none;

  @media (max-width: 900px) {
    padding: 0px;
    box-shadow: none;
    border: none;
    & .ant-card-body {
      padding: 15px;
    }
  }
`;

export const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    justify-content: flex-start;
  }
`;

export const Link = styled(Anchor)`
  color: ${({ theme }) => theme.colors.defaultColor};
`;
