import { Button, Card, Flex, Pagination, Row, Spin, Typography } from 'antd';
import { RejectDocumentModal } from 'features/assigned-clients/components/modals/reject-document-modal';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Fragment, useEffect, useState } from 'react';
import { formatUtcToLocal } from 'routes/utils';

import { ApproveLocationDocumentChangeViewModal } from './documents-change-location';

const { Title } = Typography;

export const PendingDocumentView = ({
  pendingDocument,
  // companyId,
  reFetchPendingDocument,
  documentsRefetch,
  getDocumentsCount,
  setCurrentPage,
  currentPage,
  itemsPerPage,
  isFetching,
  documentCheck,
  handleClick,
}: {
  pendingDocument: any;
  companyId: any;
  reFetchPendingDocument: () => void;
  documentsRefetch: any;
  getDocumentsCount: any;
  setCurrentPage: any;
  currentPage: any;
  itemsPerPage: any;
  isFetching: any;
  documentCheck: any;
  handleClick: any;
}) => {
  const [approveRejectModal, setApproveRejectModal] = useState(false);
  const [documentDetail, setDocumentDetails] = useState('');
  const [showAppRoveLocation, setShowArrpoveLocation] = useState(false);
  const [itemApprove, setApproveItem] = useState('');
  const [combinedData, setCombinedData] = useState<any[]>([]);

  // Combine pendingDocument and documentCheck dynamically
  useEffect(() => {
    const combineLists = () => {
      const documentCheckArray = Array.isArray(documentCheck?.rc)
        ? documentCheck?.rc
        : [];
      const pendingDocumentArray = Array.isArray(pendingDocument?.rc)
        ? pendingDocument.rc
        : [];
      // Combine and deduplicate lists based on documentId
      const combinedMap = new Map();
      documentCheckArray.forEach((doc: any) =>
        combinedMap.set(doc.documentId, doc),
      );
      pendingDocumentArray.forEach((doc: any) =>
        combinedMap.set(doc.documentId, doc),
      );
      setCombinedData(Array.from(combinedMap.values()));
    };
    combineLists();
  }, [documentCheck, pendingDocument]);
  // Pagination calculations
  const totalRecords = combinedData.length;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const paginatedData = combinedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  return (
    <Fragment>
      <Row className='fldrbx'>
        {isFetching ? (
          <Spin style={{ textAlign: 'center', width: '100%' }} size='large' />
        ) : (
          paginatedData.map((item: any, index: number) => (
            <Card key={index}>
              <Flex>
                <Typography className='meidafolder meida'>
                  <FileViewer documentPath={item.documentPath} />
                </Typography>
                <Typography className='cvnu'>
                  <Title className='crdttl' level={3}>
                    {item.documentName}
                    <dt className='stsbx'>
                      <dt
                        className={
                          item.documentStatus === 'approved'
                            ? 'approved'
                            : item.documentStatus === 'pending'
                            ? 'pending'
                            : 'rejected'
                        }
                      >
                        {item.documentStatus === 'Reject'
                          ? 'Document Rejected'
                          : item.documentStatus}
                      </dt>
                    </dt>
                  </Title>
                  <dt className='tmrbx flex'>
                    <dt className='bg-green-100 fw-500 px-3'>
                      {item?.categoryName}
                    </dt>
                    {item.documentSize != null && (
                      <dt className='tmrbx ml-3'>Size: {item.documentSize}</dt>
                    )}
                  </dt>
                  <dt className='tmrbx'>
                    Last Update: {formatUtcToLocal(item.lastUpdatedDate)}
                  </dt>
                  <dt className='button_group'>
                    {item?.documentStatus !== 'Approved' &&
                      item?.documentStatus !== 'Reject' && (
                        <div>
                          <Button
                            className='btn'
                            onClick={() => {
                              setShowArrpoveLocation(true);
                              setApproveItem(item);
                            }}
                          >
                            Approve
                          </Button>
                          {/* </Popconfirm> */}
                          <Button
                            className='btn'
                            onClick={() => {
                              setApproveRejectModal(true);
                              setDocumentDetails(item);
                            }}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                  </dt>
                </Typography>
              </Flex>
            </Card>
          ))
        )}
        {approveRejectModal && (
          <RejectDocumentModal
            onClose={() => {
              setApproveRejectModal(false);
              reFetchPendingDocument();
            }}
            documentDetail={documentDetail}
            getDocumentsCount={getDocumentsCount}
            handleClick={handleClick}
          />
        )}
        {showAppRoveLocation && (
          <ApproveLocationDocumentChangeViewModal
            onClose={() => setShowArrpoveLocation(false)}
            item={itemApprove}
            reFetchPendingDocument={reFetchPendingDocument}
            documentsRefetch={documentsRefetch}
            getDocumentsCount={getDocumentsCount}
            handleClick={handleClick}
          />
        )}
      </Row>
      {/* Pagination Component */}
      {totalPages > 1 && (
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalRecords}
          onChange={(page) => setCurrentPage(page)}
          style={{ textAlign: 'center', marginTop: '20px' }}
        />
      )}
    </Fragment>
  );
};
