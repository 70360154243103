import {
  Card,
  Col,
  Empty,
  Flex,
  Pagination,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { useGetAttentionDocumentList } from 'features/assigned-clients/use-cases/get-attention-documents';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Link2Off } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { formatUtcToLocal } from 'routes/utils';
const { Title } = Typography;

export const AttentionDocumentView = ({
  month,
  year,
  setAddCommentsModal,
  setCommentsInfo,
  setCommentsMarkInfo,
  searchName,
}: // getDocumentsCount
any) => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab: any = localStorage.getItem('activeTab'); // Read the latest value each time
  const ids = searchParams.get('clsid');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9); // Number of items per page
  const params = {
    PageIndex: currentPage,
    RecordPerPage: itemsPerPage,
  };
  const {
    data: attentionDocuments,
    isFetching,
    refetch: attention,
  } = useGetAttentionDocumentList({
    PageIndex: params?.PageIndex,
    RecordPerPage: params?.RecordPerPage,
    CompanyId: id,
    Year: year,
    Month: month,
    Name: searchName,
    ClientId: ids,
  } as any);
  const totalRecords = attentionDocuments?.rc?.[0]?.totalRecords || 0;
  const paginatedData = attentionDocuments?.rc || [];
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const handleCardClick = (item: any) => {
    setAddCommentsModal(true);
    setCommentsInfo(item);
  };

  const handleFileViewerClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevents the card click from firing
  };
  useEffect(() => {
    if (activeTab === 4) {
      attention(); // Trigger refetch if the active tab is 4
      // getDocumentsCount()
    }
  }, [activeTab, currentPage]);

  return (
    <Fragment>
      {isFetching ? (
        <Spin className='ldrloder' />
      ) : (
        <Row className='fldrbx'>
          {Array.isArray(paginatedData) && paginatedData.length ? (
            paginatedData.map((item: any, index: number) => (
              <Card
                role='button'
                key={index}
                onClick={() => {
                  handleCardClick(item);
                  setCommentsMarkInfo('Attention');
                }}
              >
                <Flex>
                  <div
                    className='meidafolder meida'
                    onClick={handleFileViewerClick}
                  >
                    {item?.documentPath == null ? (
                      <Tooltip title={'Invalid Path'}>
                        <Link2Off />
                      </Tooltip>
                    ) : (
                      <FileViewer documentPath={item.documentPath} />
                    )}
                  </div>
                  <Typography className='cvnu'>
                    <Title className='crdttl' level={3}>
                      {item.documentName}
                    </Title>
                    <dt className='tmrbx'>{item?.documentSize}</dt>
                    <dt className='tmrbx'>
                      Last Update {formatUtcToLocal(item.lastUpdatedDate)}
                    </dt>
                    <dt className='tmrbx'>
                      {item?.totalComments
                        ? `${item?.totalComments} Comments`
                        : '0 Comments'}
                    </dt>
                  </Typography>
                </Flex>
              </Card>
            ))
          ) : (
            <Col span={24}>
              <Empty description='No Attention Documents Found' />
            </Col>
          )}
        </Row>
      )}
      {totalPages > 1 && (
        <Pagination
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalRecords}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Fragment>
  );
};
