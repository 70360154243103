import dayjs from 'dayjs';
import { ClientsTable } from 'features/new-clients/components/clients-table/clients-table';
import {
  ClientsFilterFormPaginated,
  clientsFormFilterValue,
} from 'features/new-clients/components/form-filter/type';
import { ClientsTopBar } from 'features/new-clients/components/top-bar/clients-top-bar';
import { useGetClientsList } from 'features/new-clients/use-cases/get-clients';
import { NoData } from 'features/no-data/no-data';
import { useUserContext } from 'features/users';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';

const getInitialFilterValues: clientsFormFilterValue = {
  Name: undefined,
  Email: undefined,
  BusinessName: undefined,
  StatusId: undefined,
  PhoneNumber: undefined,
  date: [undefined, undefined],
  CreatedDate: undefined,
};

export const ClientsListView = () => {
  const [reminderModal, setReminderModal] = useState(false);
  const [mailModal, setMailModal] = useState(false);
  const [reminderId, setReminderId] = useState('');
  const { menuItem }: any = useUserContext();

  // Move hook calls outside of conditional statements
  const { params, updateParams, resetPage } =
    usePaginationParams<ClientsFilterFormPaginated>({
      PageIndex: 1,
      RecordPerPage: 10,
      order_by: 'name',
      ...getInitialFilterValues,
    });

  const preparePayload = (paramsw: ClientsFilterFormPaginated) => {
    const createdAtFrom = paramsw.date?.[0];
    const createdAtTo = paramsw.date?.[1];

    return {
      ...omit(params, ['date']),
      LastUpdateFrom: createdAtFrom
        ? formatDate({
            date: dayjs(createdAtFrom).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      LastUpdateTo: createdAtTo
        ? formatDate({
            date: dayjs(createdAtTo).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      CreatedDate: paramsw?.CreatedDate
        ? dayjs(paramsw.CreatedDate).format('YYYY-MM-DD')
        : null,
    };
  };

  // Conditionally use the hook based on the menuItem

  // Call useGetClientsList hook unconditionally
  const { data, isFetching } = useGetClientsList(preparePayload(params), {
    retry: false,
  } as any);

  const handleFiltersChange = (values: clientsFormFilterValue) => {
    updateParams(values);
    resetPage();
  };
  if (menuItem?.rc?.length === 0) {
    return <NoData />;
  }

  return (
    <>
      <ClientsTopBar
        setReminderModal={setReminderModal}
        reminderId={reminderId}
        reminderModal={reminderModal}
        setMailModal={setMailModal}
        mailModal={mailModal}
        handleFilters={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
      />
      <ClientsTable
        isFetching={isFetching}
        dataList={data?.rc}
        tableParams={params}
        updateParams={updateParams}
        setReminderModal={setReminderModal}
        setReminderId={setReminderId}
        setMailModal={setMailModal}
      />
      <Outlet />
    </>
  );
};
