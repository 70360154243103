import GraphikArabicMedium from 'assets/fonts/graphik-arabic-medium.woff2';
import GraphikArabicRegular from 'assets/fonts/graphik-arabic-regular.woff2';
import GraphikMedium from 'assets/fonts/graphik-medium.woff2';
import GraphikRegular from 'assets/fonts/graphik-regular.woff2';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */




  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: Poppins, sans-serif!important;
   
  }

  /* setting font size to 10px for easier rem calculations */
  html { font-size: 62.5%; };

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .ant-typography strong {
    font-weight: 500;
  }

  .prflbx{
        img{
          max-width: 125px;
        }
  }
  {}
  @font-face {
    font-family: 'Graphik';
    font-weight: 400;
    src: url(${GraphikRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 500;
    src: url(${GraphikMedium}) format('woff2');
  }

  @font-face {
    font-family: 'GraphikArabic';
    font-weight: 400;
    src: url(${GraphikArabicRegular}) format('woff2');
  }


  @font-face {
    font-family: 'GraphikArabic';
    font-weight: 500;
    src: url(${GraphikArabicMedium}) format('woff2');
  }

  .ant-picker-dropdown {
    .ant-picker-today-btn {
      color: ${({ theme }) => theme.colors.greenLight};

      &:hover {
        color: ${({ theme }) => theme.colors.greenDark};
      }
    }
  }



  .lgnbx{
      .lhead{ 
        text-align: center;
        h1{color: ${({ theme }) =>
          theme.colors
            .defaultColor}; font-size:32px; font-weight:700; margin-bottom:7px;}
        h5{margin:0px; font-size:16px; color:${({ theme }) =>
          theme.colors.bluedV2}; font-weight: normal;}
      }
      .formbx{
        label{color: ${({ theme }) => theme.colors.labelcolor};}
        .otp-group-form{ flex-flow: unset; }
      }
      .checkbx{
      margin-bottom:20px;
        .ant-form-item{
          margin-bottom:0px;
          .ant-row{
            .ant-col{
              label{white-space: nowrap;}
            }
          }
        }
      }
  }
.default-color{color: ${({ theme }) => theme.colors.defaultColor};}


.ant-btn{box-shadow:none; height: auto; padding: 5px 10px; border-radius: 5px;}
 

  /* Default Css Start */



  .pending{ background:#DBDEE8; border:1px solid #DBDEE8; color:#3D4268; }
  .approved{ background:#D4EDDA; border:1px solid #C3E6CB; color:#155724; }
  .rejected{ background:#F5E1E9; border:1px solid #F5C6CB; color:#F8516F; }



  .main-panel{

  .cncbx{
    position: relative; margin-bottom: 15px;
    .callbackarrow{ position: absolute; top: 4px; left: -5px; color: #20356A; }
    .tophead{
      margin-bottom:0px;
      .head{
        .hd_ttl{padding-left:20px;}
      }
    }
  }

  .headbx{
    margin-bottom: 15px; display: grid; grid-template-columns: 100px 1fr;    
    .hd_media, .ant-image{
      width: 100px; height: 100px; position: relative; flex: 0 0 10.4%; max-width: 115px;
      img{ height: 100%; object-fit: cover; border-radius: 5px;}
    }
    .ant-col{width:100%;max-width: 100%;}
    .hd_lbl{
      padding-left:15px;
      h4{ margin-bottom: 5px; color:#20356A; font-size: 24px; font-weight: 600; display: inline-block; cursor: pointer;}
      article{
        display:flex; align-items: center;
        & + article{margin-top: 7px; colo}
      }
      .tg_sts{
        color:#24282B; font-size:14px;
        dt{
          & + dt{margin-left:10px;}
        }
        .status{
          display: flex; border-radius: 25px; padding: 4px 10px; font-size: 12px; font-weight: 500;
        }
      }
      .tg_dte{ color:#707070; }
      
      .right_btn{
        position: absolute; right: 0px; top: 0px;
        .btn{
          font-size:13px; padding: 7px 14px;
        }
      }

    }
  }

  .crd_head{
    align-items: center; margin-bottom:15px;
    h4{margin-bottom:0px;}
    .srchrbx{
      .ant-form-item{
        margin:0px 15px 0px 0px;
        .form-control{
          background:#FAFBFC; border:1px solid #DFE1E6; border-radius:5px; color:#272727; font-weight: 500; padding:7px 14px;
        }
      }
        .ant-space{
          .ant-space-item{
            .ant-select{              
              height:auto;
              .ant-select-selector{
                background:#FAFBFC; border:1px solid #DFE1E6; border-radius:5px; color:#272727;padding:3px 14px;
              }
            }
          }
          .form-control{
            background:#FAFBFC; border:1px solid #DFE1E6; border-radius:5px; color:#272727;padding:7px 14px;
            input{ font-weight: 500; }
          }
        }
    }
  }
  
  .crdsubhed{
    margin-bottom:10px; align-items: center;
    h3{
      margin-bottom: 0px; font-size: 20px; font-weight: 600; color: #20356A; display:flex;
      .btn{ background: #F3F9EE; color: #7BA76C; font-size: 12px; font-weight: 500; padding: 4px 8px; margin-left: 15px; display: block; }
      }
    .ant-btn-group{
      .btn{}
    }
  }
    .ctgrybx{
      h4{
        font-size:16px; color:#313030; text-decoration: underline;
      }
        ul{
          list-style: none; display: flex; margin:-2px 0px 7px 0px; padding: 0px;
          li{
            color:#20356A; font-size:16px; margin: 0px; padding: 0px; position:relative;
            a{
              font-size:16px; color:#313030; text-decoration: underline; font-weight: 500;
            }
              & + li{
                margin-left:10px; padding-left:15px;
                &:after{
                  content:'>'; position: absolute; left: 0px;
                  }
              }

          }
        }
    }


  .fldrbx{
    margin-left:-7px; margin-right:-7px; margin-bottom:25px;   
    .ant-card{
      padding: 7px; border:none; width: 25%;
      .ant-card-body{
        padding:12px; background:#F6F7F9; border:1px solid #f0f0f0; border-radius:7px; height:100%;
        &:hover{
          background:#DCE1ED;
        }                
        .ant-flex{
          display: grid; grid-template-columns: 60px calc(100% - 60px);
          .meida {
            width:60px; height:60px; background:#fff; border-radius: 5px; display: flex; align-items: center; justify-content: center;
            svg{width:40px; height:40px;color: #233364;}
            .ant-image{
              width:100%; height:100%;
              img{
                width:100%; height:100%; object-fit: cover; border-radius: 5px;
              }
            }
          }
          
          .meidafolder{
            width:60px; height:60px;
            
            &.meida{
              position:relative;            
              .mtlybtnbx{
                position: absolute; background: #00000073; left: 0px; right: 0px; top: 0px; bottom: 0px; border-radius: 5px;
                display: flex; align-items: center; justify-content: center;
                .ant-btn{
                  svg{height:20px; width:20px; color:#fff;}
                }
              }
            }

            .ant-image-mask{
              .ant-image-mask-info{font-size:0px;
                svg{width:20px; height:20px; color: #fff;}
              }
            }
            .ant-image{
              width:100%; height:100%;
              img{
                width:100%; height:100%; object-fit: cover; border-radius: 5px;
              }
            }
          }

          .cvnu {
            padding-left:10px;
            dt{}
            .crdttl{
              font-size:14px; display: flex; align-items: flex-start; margin-bottom: 3px; position:relative; font-weight: 600;text-transform: capitalize;
              .stsbx{
                white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding-right: 25px;
                dt{
                  font-size: 9px; margin-left: 7px; border-radius: 25px; padding: 3px 6px; display: block; min-width: 55px;
                  &.approved{ background:#D4EDDA; border:1px solid #C3E6CB; color:#155724; }
                  &.rejected{ background:#F5E1E9; border:1px solid #F5C6CB; color:#F8516F; }
                }                
              }
              .mnubdrp{
                position: absolute; right: 0px; top: 0px;                  
              }              
              .dwnicon{
                position: absolute; right: 0px; top: 0px;
                svg{width:20px; height:20px; color:#000000;}
              }  
            }
            
              .button_group{
              div{
                display: flex; margin-top: 2px;
                .btn{
                  font-size:12px; padding: 5px 10px; font-weight: 500; color:#fff;
                  background: linear-gradient(to right, ${({ theme }) =>
                    theme.colors.defaultColor}, ${({ theme }) =>
  theme.colors.blue_light});
                  & + .btn{
                    border:1px solid #4B4B4B; background:#fff; color:#4B4B4B; margin-left:10px;
                  }
                }
                  .ant-btn{font-size: 12px; & + .ant-btn{ margin-left:10px;}}
                }
              }
              .tmrbx{font-size:12px}
              .bn_grp{
                display: flex; align-items: center; justify-content: space-between; margin-top: 5px;
                dt{
                h5{ font-size: 14px; margin:0px; color:#EC4E6E;}
                p{font-size:12px;margin:0px;}
              }
              .ant-btn{
                padding: 4px 12px; font-size: 12px; font-weight: 500; color: #fff; border-radius: 25px; border: none;
                background: linear-gradient(to right, ${({ theme }) =>
                  theme.colors.defaultColor}, ${({ theme }) =>
  theme.colors.blue_light});
              }
            }              
          }
        }

        .bn_grp{
          display: flex; align-items: center; justify-content: space-between; margin-top: 5px;
          dt{
            h5{ font-size: 14px; margin:0px; color:#EC4E6E;}
            p{font-size:12px;margin:0px;}
          }
          .ant-btn{
            padding: 4px 12px; font-size: 12px; font-weight: 500; color: #fff; border-radius: 25px; border: none;
            background: linear-gradient(to right, ${({ theme }) =>
              theme.colors.defaultColor}, ${({ theme }) =>
  theme.colors.blue_light});
          }
        }


      }
    }
  }


  .cstm_tabs{
    .ant-tabs{    
      .ant-tabs-nav{
      margin-bottom:20px;
        .ant-tabs-nav-wrap{overflow:visible;}
        &:before{display:none;}
        .ant-tabs-nav-list{          
          .ant-tabs-ink-bar{display:none}          
          .ant-tabs-tab{
            background: #F6F7F9; padding: 7px 16px; border-radius: 25px;            
            & + .ant-tabs-tab{margin-left:25px;}
            .ant-scroll-number{
              transform: translate(28px,-20px); 
              background: #E04343; border-radius: 50%; width: 27px; height: 27px; display: flex; align-items: center; justify-content: center;
              }

            &.ant-tabs-tab-active{
              background:#20356A;
              .ant-tabs-tab-btn{                
                .ant-badge{color:#fff;}              
              }
            }
          }
        }
      }
    }    
  }

  .dshbrd{
    display: grid; grid-template-columns: 80% 20%;
    .dscell{

    &.dscell.rtbx{
      .card{
        height: calc(100vh - 90px); overflow: auto;
        &::-webkit-scrollbar { width: 5px; }        
        &::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  border-radius: 10px; }        
        &::-webkit-scrollbar-thumb { background: #282938;  border-radius: 10px; }        
        &::-webkit-scrollbar-thumb:hover { background: #213678;  }
        }
     }

      & + .dscell{
        margin-left:20px; 
        .card{
          .head{
            margin-bottom:15px; 
            h3{font-size:16px;}            
          }
          height:100%;
          .nitybx{
          padding: 10px; border-radius: 5px; margin-left: -10px; margin-right: -10px;
          &:hover{background: #f7f7f7;}
          & + .nitybx{margin-top:5px;}
            .nblst{
              display:flex; 
              & + .nblst{margin-top:15px;}
              .nlicon{
                background: #E5ECF6; width: 30px; height: 30px; border-radius: 5px; display: flex; align-items: center; justify-content: center;
              }
              .nl_vlu{
              margin-left:10px; width: 77%;
                h3{ font-size: 14px; color: #1C1C1C; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 99%; font-weight: 500;}
                h6{font-size: 12px; color: #1C1C1C;}
                p{font-size:12px; color:#A2A2A2;}
              }
            }
          }
        }
    }

      .card{
        .head{
          display:flex; align-items: center; justify-content: space-between;
          h3{ font-size: 18px; color: #222529; font-weight: 500; }
          .form-group{
             .ant-select{margin-left:15px;}
             .form-control{padding:5px 10px;}             
            }
        }
      }
        .cmltycdr{
          display: flex; margin-top: 20px;
          .card{
            width:100%; position: relative;
            & + .card{margin-left:15px; margin-top:0px;}
            .grph{
              svg{width:100%}
            }
            .recharts-responsive-container{}
              .status-labels{
                position: absolute; bottom: 10px; font-size:12px; display: flex; align-items: center;
                h5{
                  & + h5{margin-left:10px;}
                }
                }
            
          }
        }

      .mltybxcard{
        display:flex; margin:0px 0px 20px 0px;
        .card{
          display:flex; width: 100%; align-items: center;

          & + .card{margin-left:15px; margin-top:0px;}
          .c_icon{}
          .c_vlu{
            margin-left:20px;
            p{font-size:16px; color:#718EBF;}
            h3{font-size:22px; color:#232323; font-weight:600;}
          }
        }
      }
    }
  }

  .editcompt{
    
    .clbkbx{
      position:relative;
      .callbackarrow{ position: absolute; top: 4px; left: -5px; color: #213267; }
      .tophead{
        .head{
          .hd_ttl{padding-left:20px;}
        }
      }
    }

    .cstm_tabs{
      .ant-tabs{
        .ant-tabs-nav{
          .ant-tabs-nav-wrap{
            .ant-tabs-nav-list{
              .ant-tabs-tab{
                background:#fff; color:#20356A;
                &.ant-tabs-tab-active{
                  background: #20356A;
                }
                & + .ant-tabs-tab{margin-left:15px;}
              }
            }
          }
        }
        .ant-tabs-content-holder{
          .ant-tabs-content{
            // border-radius: 5px; background: #fff; padding: 15px; border: none; box-shadow: 0 .75rem 1.5rem #12263f08;
          }
        }
      }      
    }
  }


  .tophead{
    display: flex; align-items: center; justify-content: space-between; margin-bottom: 15px;
    .head{
      .hd_ttl{
        h3{ font-size: 24px; color: #20356A; margin-bottom: 0px; font-weight: 500; }
      }
        .breadcrumb{
          margin: 0;
          ol{
            li{
              .ant-breadcrumb-link{
                color: ${({ theme }) =>
                  theme.colors
                    .defaultColor}; font-size: 14px; position: relative;
                a{color: ${({ theme }) => theme.colors.labelcolor};}
              }
            }
          }
        }
      
    }
  }



  .uplddocbx{
    .ant-upload-list{
      .ant-upload-list-item-container{
        width:100% !Important; height:auto !important; min-height:150px;
        .ant-upload-list-item {
          .ant-upload-list-item-thumbnail{
            .ant-upload-list-item-image{object-fit: cover;}
          }
        }
        
      }
      .ant-upload{
        width:100%; height:175px; background:#F8F8FF; margin:0px; padding-bottom:1px;
        span.ant-upload{width:100%; height:100%;}
      }
    }
  }

  
    .card{
          border-radius: 5px; background: #fff; padding: 15px; border: none; box-shadow: 0 .75rem 1.5rem #12263f08;
          
          &.filter_card{
            .filters-form{
              width: 100%; display: flex; gap: 16px;
              .ant-form-item{
                margin-bottom:0px;
                .ant-row{
                  .ant-col{
                    label{
                      &:after{display:none;}
                    }
                  }
                }
              }
            }
          }

          & + .card{margin-top:15px;}

          
          
          &.grid_secton{
            .gs_head{
              margin-bottom: 15px; display: flex; align-items: center; justify-content: space-between;
              h3{
                font-size: 16px; color: #313030; display: flex; align-items: center; font-weight: 500;
                span{
                    min-width: 35px; min-height: 35px; background: #F1F1F1; color: ${({
                      theme,
                    }) =>
                      theme.colors
                        .defaultColor}; font-size: 14px; border-radius: 50px;
                    display: flex; align-items: center; justify-content: center; margin-left: 7px; padding: 9px;
                    }
              }
            
              .buttongroup{
                .btn{ 
                    background: ${({ theme }) =>
                      theme.colors
                        .defaultColor}; border: 1px solid #e2e8f0; border-radius: 5px; color: #fff; padding: 7px 14px; font-size: 13px; 
                    font-weight: 500;
                  & + .btn{}
                }
              }

            } /* head End */

            .cstmtblbx{
              .cstm_table{
                
                .ant-table-content{
                overflow: auto;
                
                  table{
                    border-top: 1px solid ${({ theme }) =>
                      theme.colors
                        .defaultbordercolor}; border-bottom: 1px solid ${({
  theme,
}) => theme.colors.defaultbordercolor};
                    position: relative; border-radius:0px;
                    tr{
                      th,td{
                        padding: 5px 7px; border-right: 0; vertical-align: middle; min-width: 45px; line-height: 34px;white-space: nowrap;
                      }
                    }
                    thead{
                      tr{
                        th{
                          font-weight: 500; font-size: 14px; color: #02140c; border-bottom: 2px solid ${({
                            theme,
                          }) => theme.colors.defaultheaderbordercolor};
                          font-size:13px; 
                        }
                      }
                    }
                      tbody{
                        tr{
                          td{
                            font-size:13px;
                            .grdbtngroup{
                              .btn{
                                border: none; box-shadow: none; display: flex; align-items: center; justify-content: center; padding: 0px; width: 24px; height: 24px;
                                border-radius: 0px; background: transparent;
                                &:hover{color:#20356A;}
                              }
                            }
                          }
                        }
                      }
                  }
                }

              } /* Custom Table End */
            } /* Custom Table Box End */



          } /* grid secton End */

        } /* card End*/
  } /* Main Panel End*/
        



  .docupldmdl{
    .ant-modal-body{
      padding:10px 0px;
      h3{
        text-align: center; font-size: 18px; line-height: 28px; margin-bottom: 25px; color:#24282B;
      }
      .ant-form-item{
        .ant-row{
          .ant-col{
            padding:0px;
            label{font-size:13px; color:#262626;}
            dt{font-size:11px; color:#7E84A3;}
            .ucbx{
              margin-top: 10px; background: #F5F6FA; padding: 15px; text-align: center; display: flex; justify-content: center; border-radius: 5px;
              .ant-upload-wrapper{
                .ant-upload-list{
                  .ant-upload{
                    border:none;background-color:transparent;
                    .anticon{
                      svg{ width: 45px; height: 40px; }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  

label{ color: ${({ theme }) => theme.colors.labelcolor}; }
h1,h2,h3,h4,h5,h6{ color: ${({ theme }) => theme.colors.defaultColor};}
.btn{ padding:8px 14px; height: auto; }
.btn-primary{
  color: #fff; border: none; font-weight: 500; border-radius: 3px;
  background: linear-gradient(to right, ${({ theme }) =>
    theme.colors.defaultColor}, ${({ theme }) => theme.colors.blue_light});
  &:hover{background: linear-gradient(to right, ${({ theme }) =>
    theme.colors.defaultColor}, ${({ theme }) => theme.colors.blue_light});}   
}
.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover {background: linear-gradient(to right, ${({
  theme,
}) => theme.colors.blue_light}, ${({ theme }) =>
  theme.colors.defaultColor});color:#fff;}
:where(.css-dev-only-do-not-override-11us06p).ant-btn-variant-link:not(:disabled):not(.ant-btn-disabled):hover { color:${({
  theme,
}) => theme.colors.blue_light};}


.active-menu { font-weight: bold; color: #1890ff; }
.d-flex{display:flex;}

.form-control{ border:1px solid #E2E8F0; color:#263448; font-size:14px; border-radius: 5px; padding: 9px 14px;}
 

  .fw-500{font-weight:500;}

  .default_gradient{ 
    background: linear-gradient(to right, ${({ theme }) =>
      theme.colors.defaultColor}, ${({ theme }) => theme.colors.blue_light}); 
    border:1px solid ${({ theme }) =>
      theme.colors.lightgray}; box-shadow: none; font-weight: 500;
  }


  .ldrloder{padding:50px; width:100%;}



  .rightmodal{
    .ant-modal{
      margin: 0px; position: fixed; right: 0px; top: 0px; bottom: 0px; background: #fff;
      .ant-modal-content{
        border: none; box-shadow: none; border-radius: 0px;  
        .ant-modal-header{
          padding-bottom: 15px; margin-bottom: 20px; border-bottom: 1px solid #f0f0f0;
          .ant-modal-title{font-size: 2.2rem;}          
        }      
        .ant-modal-body{
          height: calc(100vh - 160px); overflow: auto;
          &::-webkit-scrollbar { width: 5px; }        
          &::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  border-radius: 10px; }        
          &::-webkit-scrollbar-thumb { background: #282938;  border-radius: 10px; }        
          &::-webkit-scrollbar-thumb:hover { background: #213678;  }
          h3{color:#20356A;}
          
          .ant-form{
            
            .ant-form-item{
              margin-bottom:15px;
              .ant-row {
                .ant-col{padding:0px 0px 3px 0px;}
              }
            }
          }

          .ant-collapse-item{
            .ant-collapse-header{
              .ant-collapse-header-text{font-size:16px; color:#16192C;}
            }
            .ant-collapse-content{
              .ant-collapse-content-box{}
            }
          }
        }
        .ant-modal-footer{
          margin: 0px; padding: 10px 0px;
          .ant-btn{ font-size: 14px; font-weight: 500; padding: 8px 25px; min-width: 150px; }
          }
      }
    }
  }

  /* Default Css End */


@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .btn-primary, .ant-btn{padding: 4px 8px;}
  .padding-tb-7{padding: 7px 8px;}
  .main-panel .headbx .hd_lbl .right_btn .btn{padding: 4px 8px;}
  .main-panel .card{ padding: 12px; }
  .main-panel .tophead .head .hd_ttl h3{ font-size: 20px; }
  .main-panel .crdsubhed h3 { font-size: 18px; }
  .main-panel .crd_head h4 { font-size: 20px; }
  .main-panel .fldrbx .ant-card .ant-card-body .ant-flex .cvnu .crdttl{font-size: 12px;} 
  .main-panel .fldrbx .ant-card .ant-card-body .ant-flex .cvnu .crdttl .stsbx dt{padding: 2px 4px;font-size: 7px;margin-left:0px; display: inline-block;}
  .main-panel .fldrbx .ant-card .ant-card-body .ant-flex .cvnu .crdttl{display:block;}
  .main-panel .fldrbx .ant-card .ant-card-body .ant-flex .cvnu .crdttl .stsbx{ margin-top: 3px; }
  .main-panel .dshbrd .dscell .card .head h3{font-size: 16px;}
  .cotlqL.side-bar-menu .ant-menu-title-content a{    font-size: 13px;}
  .sidebarl .ant-layout-sider .side-bar-menu .ant-menu-item .ant-menu-item-icon svg,
  .sidebarl .ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-item-icon svg{ height: 18px !important; }
  .sidebarl .ant-layout-sider .ant-menu .ant-menu-item{line-height: 35px;}
  .sidebarl .ant-layout-sider .side-bar-menu .ant-menu-item .ant-menu-item-icon,
  .sidebarl .ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-item-icon{width:18px !important; height:18px !important;}
  
}





@media screen and (min-width: 1024px) and (max-width: 1600px) {
 
  .main-panel {

  .dshbrd {
    .dscell {
      .mltybxcard {
        .card{
          padding:10px;
          .c_icon{
            width:50px;
            img{width:100%;}
          }
            .c_vlu{
              margin-left:10px;
              p{font-size:14px;}
              h3{font-size:20px;}
            }
        }
      }
    }
  }

    .fldrbx{
      .ant-card{width:33.33%;}
    }

    .dshbrd{
      display: flex;
      .dscell{
        width:75%;
        & + .dscell{
        width:25%; position: sticky; top: 0;
        .card{position: sticky; top: 75px;}
        }
      }
    }
  }
    // /* End Main panel */

 }

 @media(max-width:980px){

//  /* mobile Center Modal Start */ 

.otpbx{
  .ant-row{
    .ant-col{
      .ant-form{
        .ant-row {
          .ant-col{
            padding-left: 5px !important; padding-right: 5px !important;
            .ant-input{
              padding:9px 0px;
            }
          }
        }
          .ant-form-item{
            .ant-row{
              .ant-col{
                .ant-form-item-control-input{
                  .ant-form-item-control-input-content{
                    .ant-btn{padding:8px 8px; margin-top:25px !important;}
                  }
                }
              }
            }
          }
      }
    }
  }
}

  .mbl_modal_center{
    top: 0px !important; margin: 0px !important; max-width: 100% !important; bottom: 0px; position: fixed; padding: 0px; border-radius: 0px; box-shadow: none; 
    background: #fff; left: 0px; height: 100vh;
    .ant-modal-content{
      border-radius: 0px; box-shadow: none; padding: 16px;
      .ant-modal-body{

        .ant-form{
          
          .jRKArt{
            .ewamOi{gap: 0px;}
          }

          .ant-form-item { 
            margin-bottom:15px;
              .ant-row{
                  .ant-col{
                    padding-bottom:3px;
                    label{}
                    .ant-input{}
                    .ant-form-item-control-input{
                      .ant-upload-list{
                        width: 100%; display: block;
                        .ant-upload{
                          width: 100%;
                          button{div{margin-top:0px !important;}}
                        }
                      }
                    }
                  }
              }
            }
        }

        .form_fltrbx{
          gap:0px;
            .ant-form-item {
              margin-bottom:15px;
                .ant-row{
                  .ant-col{
                    padding-bottom:3px;
                    label{}
                    .ant-input{}
                  }
                }
            }
        }
      }

      .ant-modal-footer{     
        padding:0px; display: flex;
        .ant-btn, .btn{padding:8px 14px;width:100%; font-size:14px;}
        .cstmfull{width:100%; margin: 0px;}

        .kczTVI{
          .kuNtAX{
            display:flex;flex-wrap: nowrap;
            button{width:100%;}
          }
        }
      }
    }
  }

  .rightmodal {
    .ant-modal{
      max-width:100%;
      .ant-modal-content{
        padding:20px;
        .ant-modal-body{
          .ant-form{
            .ant-row{              
              .ant-btn{padding:8px 14px;width:100%;}
            }
          }
        }
      }
    }
  }

 .main-panel .tophead .head .hd_ttl h3{font-size: 18px;}
 .main-panel .tophead .head .breadcrumb ol li .ant-breadcrumb-link{ font-size: 12px; }
 .ant-btn{ padding: 4px 8px; border-radius: 4px; font-size: 13px; font-weight: 500; }

 .main-panel .fldrbx .ant-card .ant-card-body .ant-flex{ grid-template-columns: 80px calc(100% - 80px); }
 .main-panel .fldrbx .ant-card .ant-card-body .ant-flex .meidafolder{ width: 80px; height: 80px; }

  .main-panel .fldrbx .ant-card .ant-card-body .ant-flex .cvnu .crdttl .stsbx{
    min-width: 105px; 
    dt{width: max-content;}
  }
 

  .sssbx {
    .ant-row{
      .ant-col{
        .ant-form-item{
          .ant-row{
            flex-flow: nowrap; justify-content: space-between; align-items: center;
            .ant-col{
              max-width: fit-content; padding: 0px;
              label{font-weight:500;}
            }
          }
        }
        .ant-space{
          width:100%;
          .ant-space-item{
            width:100%;
            .ant-form-item{
              .ant-row{
                display:block;
                .ant-col{
                  max-width:unset;
                  .ant-btn{
                    width:100%; padding: 7px;
                  }
                }                
              }
            }
          }
        }
      }
    }
  }

 
 /* Responsive Design for mobile view Start */

  .lgnbx {
    .lhead{text-align: left; margin-bottom:25px;}
    .lgllogo{
      padding: 15px 15px 15px 0px; display: flex; align-items: center; justify-content: flex-start;

    }
  }
  
  .app-header{
    .logo{
      flex-direction: row-reverse;
      img{max-width: 135px !important; width: auto;}
      svg{ margin: 3px 5px 0px 0px; width: 25px; height: 25px; }
    }
  }
  
  .main-panel {

  .ctgrybx {
    ul{
      flex-wrap: wrap;
      li{
          font-size: 13px;      
          a{
                font-size: 13px;
          }
      }
    }
  }

    .cncbx{
      border-radius: 5px; background: #fff; padding: 15px; border: none; box-shadow: 0 .75rem 1.5rem #12263f08; position: relative; margin-bottom: 15px;
      .callbackarrow{ position: absolute; top: 17px; left: 10px; color: #20356A; }
    }

    &.mblvwmainpage{      
      .headbx {      
        background: #fff; padding: 10px 15px 10px 10px; border: none; box-shadow: 0 .75rem 1.5rem #12263f08; 
        &.hdwthicn {
          display: block;
          .bxicon{position:absolute;left: 4px; z-index: 1; width:25px;}
          .hd_lbl {
            h4{padding-left:20px;}
          }
        }
      }
    }

    .editcompt{
      .clbkbx{
        display:flex; align-item:center; border-radius: 5px; background: #fff; padding: 10px; box-shadow: 0 .75rem 1.5rem #12263f08; margin-bottom:15px;
        .callbackarrow{top:11px; left:5px;}
        .tophead{margin-bottom:0px;}
      }
    }


    .cstm_tabs{
      .ant-tabs{
        .ant-tabs-nav{
          overflow:auto; padding-bottom: 10px; padding-top: 10px;
          .ant-tabs-nav-wrap{
            width: 100%;
            &:before,&:after{display:none;}
          }
          .ant-tabs-nav-list{
            .ant-tabs-tab{
              & + .ant-tabs-tab{ margin-left: 15px;}
            }
          }
        }
        .ant-tabs-content{
          .asdasd {
            .ant-col{              
              .ant-form-item{

                .ant-upload-list{
                  width:100%; display: block;
                  .ant-upload-list-item-container{
                    width:100%; height: auto;
                    .ant-upload-list-item {
                      position:relative; height: 225px; overflow: hidden;
                      img{
                        position: absolute; left: 0px; right: 0px; top: 0px; bottom: 0px; margin: auto; width: auto; height: auto; max-width: 100%;                        
                      }
                    }
                  }
                }
              }
              .ant-row{
                .ant-col{
                  .ant-form-item-control-input{
                    .ant-form-item-control-input-content{
                      .ant-upload-wrapper{
                        .ant-upload-list{
                          width:100%;
                          .ant-upload-list-item-container{
                            width:100%;
                          }
                        }
                      }
                    }
                  }
                  .ant-form-item{
                    margin-bottom: 20px;
                  }
                  .ant-space{
                    width:100%;
                  }                
                }              
              }
            }
          }
        }
      }
    }

    .crd_head {
      &.acbx{
        display:block;
        .ant-col{ 
          width:100%; max-width: 100%; 
          .srchrbx{padding:10px 0px;}
        }
      }            
    }

    .crdsubhed {
      display:block; margin-bottom: 20px;
        h3{ margin-bottom: 15px; font-size: 18px; justify-content: space-between;}
        .ant-btn-group{
          width: 100%; display: block; position: fixed; bottom: 0px; left: 0px; right: 0px; padding: 20px; background: #fff; box-shadow: 0px -4px 7px #2132672b;
          z-index: 1;
          .ant-btn{
            border: 1px solid #20356A; background: transparent; color: #20356A; padding: 7px 14px; width: 100%; font-size: 14px;
          }
          .btn{
            border: 1px solid #20356A; background: transparent; color: #20356A; padding: 7px 14px; width: 100%; font-size: 14px;
            justify-content: center; align-items: center;
          }
        }
    }

    .fldrbx {
      padding-bottom:35px;
      .ant-card{width:100%;}
    }

    
    .headbx{
      grid-template-columns:27px 1fr;    
      .hd_media {
        height:auto;
          .callbackarrow{
            height: 25px; width: 25px; margin-top: 2px; color: #233364;
          }
        }
      .hd_lbl {
        padding-left:0px;
          h4{font-size:20px; margin: 0px; min-height: 28px;}
          .tg_sts {
            .status{ border-radius: 5px; padding: 3px 6px; font-size: 10px; letter-spacing: 0.3px;}
          }
          .tg_dte {margin-top:3px;font-size: 12px;}
      }
      .right_btn{
        .btn{ font-size: 12px !important; padding: 3px 6px !important; }        
      }
    }

    .card{
      border-radius: 3px;
      
      &.inrfltr{
        .ant-form{
          flex-wrap: wrap;
          div{
            width:100%;
            .ant-btn{ width: 47%; }
          }
        }
      }

      .ant-form{
        .ant-row{        
          .sssbx{            
            .ant-col{
              .ant-form-item{
                margin-bottom:20px;
              }
              .button_group{
                width:100%;
                .ant-space-item{
                  width:100%;
                  button{width:100%; padding: 7px 8px;}
                }
              }
            }
          }
        }
      }
        
    }





    
    .mbl_flter{
      position:absolute; right:0px; top:8px;
      .fltbtn{
        // background: transparent; border: none;
        background: #fff; color: #233364; font-size: 12px; font-weight: 500; padding: 6px 10px;
        svg{height:15px; width:15px;}
      }
    }

    .dshbrd{
      display:block;
        .dscell{
          & + .dscell{margin-left:0px; margin-top:20px;}
          
          .cmltycdr{
            display:block;
            .card{
              .status-labels{ justify-content: center; left: 0px; right: 0px; bottom: 15px;}
              & + .card{margin-left:0px; margin-top:20px;}
            }
          }
          .mltybxcard{
            display: grid; grid-template-columns: 1fr 1fr; flex-wrap: wrap; gap: 15px;
            .card{
              padding: 10px; margin-left: 0px !important;
              .c_icon{width:40px;}
              .c_vlu{ }
              }
          }
        }
    }
  }


 /* Responsive Design for mobile view End */



  .global_slots{
    .slot_mbl{
      .halfHours{
        .sc-ckEbSK{
          display: flex; align-items: center;
          span.ant-typography{
            display:none;
          }
          .sc-GhhNo{
            width: 100%;
            span{
              display:inline-block;
            }
          }
        }
      }
    }
  }
}
  .sidebarl{
  .ant-layout-sider{

    .ant-menu{
        gap: unset;
      .ant-menu-item{
        padding: 5px 0px 5px 15px !important; height:auto;
        &:hover{
          background:#20212D;
        }
        & + .ant-menu-item{border-top: 1px solid #33323d; margin-top:5px;}
      }
      .ant-menu-submenu{
        padding: 5px 0px 5px 15px !important; height:auto;
        .ant-menu{
          padding-bottom: 25px; margin-left:18px;
          .ant-menu-item {
            .ant-menu-title-content{
              a{
                line-height: normal;
                &:hover{color:#fff;}
              }
            }
              & + .ant-menu-item{border:none}
          }  
        } 
        .ant-menu-submenu-title{
          padding-left: 0px !important;
          .ant-menu-item-icon{
            width:24px; height:24px;display:inline-block;
            svg{color:#B4B4BA; height:22px;}
          }
            .ant-menu-submenu-arrow{color:#fff;} 
        }
         &:hover{
          background:#20212D;
        }
      }
    }

    .side-bar-menu{
      .ant-menu-item{
        .ant-menu-item-icon{
          width:24px; height:24px;display:inline-block;
          svg{color:#B4B4BA; height:22px;}
        }        
      }
    }

    
    .ant-layout-sider-trigger{
      background:${({ theme }) => theme.colors.leftmenu}; display: none;
    .ant-menu{
        padding-bottom: 25px;
      }
    }
    .ant-layout-sider-trigger{
      background:${({ theme }) => theme.colors.leftmenu};

    }
  }
}
  .sidebarl{
  .ant-layout-sider{
    .ant-layout-sider-children{
      .ant-menu{
        padding-bottom: 25px;
      }
-layout-sider{
    .ant-layout-sider-children{
      .ant-menu{
        padding-bottom: 25px;
      }
 25px;
      }
    }
    .ant-layout-sider-trigger{
      background:${({ theme }) => theme.colors.leftmenu};
nt-menu{
        padding-bottom: 25px;
      }
    }
    .ant-layout-sider-trigger{
      background:${({ theme }) => theme.colors.leftmenu};
     .ant-menu{
        padding-bottom: 25px;
      }
    }
    .ant-layout-sider-trigger{
      background:${({ theme }) => theme.colors.leftmenu};
    }
  }
}
  .ant-upload-list-item.ant-upload-list-item-error {
    border: 1px solid #d9d9d9 !important;
}









 


`;
