import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Dropdown,
  Flex,
  Menu,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useDeleteDocuments } from 'features/assigned-clients/use-cases/delete.documents';
import FileViewer from 'features/new-clients/components/clients-details/file-viewer';
import { Folder, FolderPlus } from 'lucide-react';
import { Fragment, useState } from 'react';
import { getFileExtension } from 'routes/utils';

import { SecondCategoryDocumentView } from './second-category-list';

const { Title } = Typography;
export const SubCategoryDocumentView = ({
  subCategoryDocument,
  setCategoryList,
  setShowSubCategory,
  setMoveToModal,
  setDocumentsInfo,
  setCommentsInfo,
  setAddCommentsModal,
  documentsRefetch,
  secondCategoryData,
  setSecondCategoryData,
}: {
  subCategoryDocument: any;
  setCategoryList: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubCategory: React.Dispatch<React.SetStateAction<boolean>>;
  setMoveToModal: any;
  setDocumentsInfo: any;
  setCommentsInfo: any;
  setAddCommentsModal: any;
  documentsRefetch: any;
  secondCategoryData: any;
  setSecondCategoryData: any;
}) => {
  const [showSubSecondCategory, setShowSubSecondCategory] = useState(true);
  const [showSecondCategory, setShowSecondCategory] = useState(false);
  // const [secondCategoryData, setSecondCategoryData] = useState({});  // if any error then open statte
  const { mutate } = useDeleteDocuments();
  const showCategory = () => {
    setCategoryList(true);
    setShowSubCategory(false);
  };
  const showSecondCategoryData = (data: any) => {
    setShowSubSecondCategory(false);
    setShowSecondCategory(true);
    setSecondCategoryData(data);
  };

  const onChangeDelete = (valueInfo: any) => {
    const formValue = {
      DocumentId: valueInfo.documentId,
      IsAdmin: true,
    };
    mutate(formValue, {
      onSuccess: (data: any) => {
        if (data.rs === 1) {
          message.success('Deleted successfully');
          window.location.reload(); // This will refresh the current page
          documentsRefetch();
        } else {
          message.error('Failed to delete the document');
        }
      },
      onError: (error) => {
        message.error('An error occurred while deleting the document.');
        console.error('Delete error:', error);
      },
    });
  };

  const handleMenuClick = ({ key }: any, item: any) => {
    if (key === 'download') {
      message.success('Downloading ' + item.documentName);
      const link = document.createElement('a');
      link.href = item.documentPath; // File path or URL
      link.target = '_blank'; // Open in a new tab
      link.download = item.documentName; // Suggested file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (key === 'preview') {
      // Open the preview in a new tab
      Modal.info({
        title: 'Document View',
        width: '100%',
        icon: null,
        closable: true,
        closeIcon: <CloseOutlined />,
        style: { top: '0px', maxWidth: '500px' },
        content: (
          <div style={{ textAlign: 'start' }}>
            {(() => {
              const extension = getFileExtension(item.documentPath);

              if (['jpg', 'jpeg', 'png', 'gif'].includes(extension!)) {
                // If it's an image, display it directly
                return <iframe src={item.documentPath} width='100%' />;
              } else if (
                ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'].includes(
                  extension!,
                )
              ) {
                // Use Google Docs Viewer for documents
                return (
                  <iframe
                    src={`https://docs.google.com/gview?url=${item.documentPath}&embedded=true`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              } else {
                return (
                  <iframe
                    src={`${item.documentPath}`}
                    width='100%'
                    height='500'
                    style={{ border: 'none' }}
                    title='Document Preview'
                  />
                );
              }
            })()}
          </div>
        ),
        okButtonProps: { style: { display: 'none' } },
      });
    } else if (key === 'Move To') {
      setMoveToModal(true);
      setDocumentsInfo(item);
    } else if (key === 'Delete') {
      onChangeDelete(item);
    } else if (key === 'Add Comment') {
      setAddCommentsModal(true);
      setCommentsInfo(item);
    }
  };

  const renderMenu = (item: any) => {
    const extension = getFileExtension(item?.documentPath);

    // Check if the file extension is a zip file
    const isZipFile = extension === 'zip';

    return (
      <Menu
        onClick={({ key }) => handleMenuClick({ key }, item)}
        items={[
          { label: 'Download', key: 'download' },
          // Only show "Preview" if it's not a ZIP file
          ...(isZipFile ? [] : [{ label: 'Preview', key: 'preview' }]),
          { label: 'Move To', key: 'Move To' },
          { label: 'Add Comment', key: 'Add Comment' },
          { label: 'Delete', key: 'Delete' },
        ]}
      />
    );
  };

  return (
    <Fragment>
      <Typography className='ctgrybx'>
        {showSubSecondCategory && (
          <ul>
            <li>
              <a href='javascript:void();' onClick={() => showCategory()}>
                Categories
              </a>
            </li>
            <li>{subCategoryDocument.categoryName}</li>
          </ul>
        )}
      </Typography>

      {showSubSecondCategory && (
        <Row className='fldrbx'>
          {Array.isArray(subCategoryDocument.subCategoryList) &&
          subCategoryDocument.subCategoryList.length
            ? subCategoryDocument.subCategoryList?.map(
                (item: any, index: number) => (
                  <Card
                    hoverable
                    key={index}
                    role='button'
                    onClick={() => {
                      if (item.noOfDocuments > 0) {
                        showSecondCategoryData(item);
                      }
                    }}
                  >
                    <Flex>
                      {/* <Typography className='meida'> */}
                      <div className='meidafolder'>
                        {item.noOfDocuments > 0 ? (
                          <Folder
                            fill='#7D819E'
                            stroke='#7D819E'
                            className='meidafolder'
                          />
                        ) : (
                          <FolderPlus
                            stroke='#7D819E'
                            className='meidafolder'
                          />
                        )}
                      </div>

                      {/* </Typography> */}
                      <Typography className='cvnu'>
                        <Title className='crdttl' level={3}>
                          {item.subCategory}
                        </Title>
                        {item.noOfDocuments > 0 ? (
                          <dt className='tmrbx'>
                            {item.noOfDocuments} Documents
                          </dt>
                        ) : (
                          <dt className='tmrbx'>Empty Folder </dt>
                        )}

                        {item.lastUpdatedDate && (
                          <dt className='tmrbx'>
                            Last Update{' '}
                            {dayjs(item.lastUpdatedDate).format(
                              'DD MMM YYYY | hh:mm A',
                            )}
                            {/* 24 Sep, 2024 | 03:11 PM */}
                          </dt>
                        )}
                      </Typography>
                    </Flex>
                  </Card>
                ),
              )
            : ''}
        </Row>
      )}

      {showSubSecondCategory && (
        <Col span={24}>
          {subCategoryDocument.documentList.length > 0 && (
            <Title level={4}>Document</Title>
          )}
          <Fragment>
            <Row className='fldrbx'>
              {Array.isArray(subCategoryDocument.documentList) &&
              subCategoryDocument.documentList.length
                ? subCategoryDocument.documentList?.map((item: any) => (
                    <Card key={item?.documentId}>
                      <Flex>
                        <Typography className='meidafolder meida'>
                          {/* <Image src={item.documentPath} /> */}
                          <FileViewer documentPath={item.documentPath} />
                        </Typography>
                        <Typography className='cvnu'>
                          <Title className='crdttl' level={3}>
                            {item.documentName}

                            <dt className='stsbx'>
                              <dt
                                className={
                                  item.documentStatus === 'Approved'
                                    ? 'approved'
                                    : item.documentStatus == 'Pending'
                                    ? 'pending'
                                    : 'rejected'
                                }
                              >
                                {item?.documentStatus == 'Reject'
                                  ? 'Document Rejected'
                                  : item?.documentStatus}
                              </dt>
                            </dt>

                            <dt className='mnubdrp'>
                              <dt className='mnubdrp'>
                                <Dropdown
                                  overlay={renderMenu(item)}
                                  trigger={['click']}
                                >
                                  <MoreOutlined
                                    style={{
                                      fontSize: '24px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Dropdown>
                              </dt>
                            </dt>
                          </Title>
                          <dt className='tmrbx'>
                            {item?.totalComments
                              ? `${item?.totalComments} Comments`
                              : '0 Comments'}
                          </dt>
                          {item.documentSize != null ? (
                            <dt className='tmrbx'>
                              Size : {item.documentSize}{' '}
                            </dt>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </Flex>
                    </Card>
                  ))
                : ''}
            </Row>
          </Fragment>
        </Col>
      )}

      {showSecondCategory && (
        <SecondCategoryDocumentView
          secondCategoryDocument={secondCategoryData}
          setCategoryList={setCategoryList}
          setShowSubCategory={setShowSubCategory}
          subCategoryName={subCategoryDocument.categoryName}
          setShowSecondCategory={setShowSecondCategory}
          setShowSubSecondCategory={setShowSubSecondCategory}
          setMoveToModal={setMoveToModal}
          setDocumentsInfo={setDocumentsInfo}
          setCommentsInfo={setCommentsInfo}
          setAddCommentsModal={setAddCommentsModal}
        />
      )}
    </Fragment>
  );
};
