import {
  BellOutlined,
  LogoutOutlined,
  SettingOutlined,
  // SettingOutlined,
} from '@ant-design/icons';
import { Badge, Col, Dropdown, Grid, message, Space } from 'antd';
import logoimg from 'assets/images/logo.png';
import { Avatar } from 'components';
import { useSessionContext } from 'features/auth';
import { DashBoardApiService } from 'features/Dashboard/api/dashboard-api.service';
import { useNotificationSeen } from 'features/Dashboard/use-cases/create-notification-seen';
import { useUserContext } from 'features/users';
import { AlignJustify } from 'lucide-react';
import { useSideBar } from 'providers/side-bar-provider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import {
  StyledDropdownIcon,
  StyledHeader,
  StyledUserInfo,
  StyledUserMenuButton,
} from './header.styles';

const { useBreakpoint } = Grid;

export const Header = () => {
  // const { collapsed, toggleCollapsed } = useSideBar();
  const { logOut } = useSessionContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const avatarSize = 32;
  const { toggleCollapsed } = useSideBar();
  const { mutate } = useNotificationSeen();
  const userId = localStorage.getItem('userId');
  // const { data } = useGetNotificationCountList('' as any);
  const [data, setData] = useState<any>('');
  const initial = async () => {
    try {
      const apiRes = await DashBoardApiService().GetNotificationsCount(
        '' as any,
      );
      setData(apiRes);
    } catch (error: any) {
      message.error(error);
    }
  };

  useEffect(() => {
    initial();
  }, []);
  // Prevent dropdown from opening when clicking on the bell icon
  const handleBellClick = (e: any) => {
    const payloadValue: any = {
      LoggedInUserId: userId,
    };
    mutate(payloadValue, {
      onSuccess: (data1: any) => {
        if (data1?.rs == 1) {
          message.success('Notification Seen');
          initial();
        } else {
          message.error(data1?.rm);
        }
      },
    });
    navigate('/');
    e.stopPropagation(); // Prevents event bubbling to the dropdown
  };
  if (!user) return null;
  
return (
    <StyledHeader className='app-header shadow-md app-header'>
      <Col className='d-flex items-center logo'>
        <img
          className='w-1/2'
          // style={{ maxWidth: '200px' }}
          src={user?.ClientImage || logoimg}
          alt=''
        />
        <AlignJustify
          className='mx-14 cursor-pointer'
          onClick={toggleCollapsed}
        />
      </Col>

      <Space className='app-header-user-wrapper'>
        <Dropdown
          menu={{
            items: [
              {
                key: 'accountSettings',
                label: t('userMenu.accountSettings'),
                icon: <SettingOutlined />,
                onClick: () => navigate(ROUTES.ACCOUNT_SETTINGS),
              },
              {
                key: 'logout',
                label: t('userMenu.logOut'),
                icon: <LogoutOutlined />,
                onClick: logOut,
              },
            ],
          }}
          trigger={['click']}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <StyledUserMenuButton
            className='app-header-user'
            ghost
            $avatarSize={avatarSize}
            aria-label={t('userMenu.toggle')}
          >
            <Space>
              <Badge
                style={{ right: '13px' }}
                count={data?.rc?.[0]?.totalCount}
              >
                <BellOutlined
                  onClick={handleBellClick}
                  style={{
                    color: 'black',
                    width: '34px',
                    height: '34px',
                    background: '#F3F3F3',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '15px',
                  }}
                />
              </Badge>
            </Space>

            <Avatar
              size={avatarSize}
              src={user?.ProfileImage}
              className='app-header-avatar'
            />
            <Space className='app-header-space'>
              <StyledUserInfo ellipsis={{ tooltip: true }}>
                {/* {getUserFullName(user)} */}
                {user?.Name}
              </StyledUserInfo>
            </Space>
            {screens.lg ? (
              <StyledDropdownIcon style={{ marginLeft: '10px' }} />
            ) : null}
          </StyledUserMenuButton>
        </Dropdown>
      </Space>
    </StyledHeader>
  );
};
