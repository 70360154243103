import { message, Upload } from 'antd';
import dayjs from 'dayjs';

import { RoutePaths } from './constants';

export const getRoute = (route: RoutePaths, id: string | number) => {
  return route.replace(`:id`, id.toString());
};

export const reload = () => {
  window.location.reload();
};
export const validateFileBeforeUpload = (file: File) => {
  // Allowed file types
  const allowedTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'text/csv',
    'application/zip', // .zip
    'application/x-zip-compressed', // .zip (older MIME type)
  ];

  // Video MIME types to exclude
  const videoTypes = [
    'video/mp4',
    'video/x-msvideo', // .avi
    'video/x-matroska', // .mkv
    'video/mpeg',
    'video/webm',
  ];

  if (videoTypes.includes(file.type)) {
    message.error('Video files are not allowed!');
    
return false; // Reject video files by returning false
  }

  const isValidType = allowedTypes.includes(file.type);

  if (!isValidType) {
    message.error(
      'You can only upload PNG, JPG, JPEG, CSV, PDF, ZIP,Excel, or Word files!',
    );
  }

  return isValidType; // Return true if file type is valid, false otherwise
};
export const validateImageBeforeUpload = (file: File) => {
  // Allowed image file types
  const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

  const isValidType = allowedTypes.includes(file.type);

  if (!isValidType) {
    message.error('You can only upload PNG, JPG, JPEG, or WEBP images!');

    return Upload.LIST_IGNORE; // Reject invalid files
  }

  return false; // Accept valid files
};
export const MonthValue = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const disabledDate = (current: dayjs.Dayjs) => {
  const today = dayjs();
  const endOfNextTwoMonths = today.add(2, 'month').endOf('month'); // End of the next two months

  // Disable future months beyond the next two months
  return current.isAfter(endOfNextTwoMonths, 'month');
};
export const getFileExtension = (url: string): string | null => {
  const pathname = new URL(url).pathname; // Extract the path from the URL
  const match = pathname.match(/\.(\w+)(?:\?|$)/); // Match file extension

  return match ? match[1].toLowerCase() : null;
};
export const formatUtcToLocal = (utcDate: any) => {
  if (!utcDate) return 'N/A'; // Fallback for missing data

  return dayjs
    .utc(utcDate) // Parse the date as UTC
    .local() // Convert to local time
    .format('D MMM, YYYY | h:mm A'); // Format the date
};
export const convertFileSize = (bytes: any) => {
  const KB = 1024;
  const MB = 1024 * KB;
  const GB = 1024 * MB;

  if (bytes < KB) {
    return `${bytes} Bytes`;
  } else if (bytes < MB) {
    return `${(bytes / KB).toFixed(2)} KB`;
  } else if (bytes < GB) {
    return `${(bytes / MB).toFixed(2)} MB`;
  } else {
    return `${(bytes / GB).toFixed(2)} GB`;
  }
};
