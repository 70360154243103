import { Form, Grid } from 'antd';
import { Modal } from 'components';
import { RoleInfoFormItems } from 'features/role-management/components/modal/role-info-item';
import {
  RoleFormField,
  RoleFormValues,
} from 'features/role-management/components/role-filter-field/type';
import { useEditRole } from 'features/role-management/use-cases/edit-role';
import { useGetRoleById } from 'features/role-management/use-cases/get-role';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const { useBreakpoint } = Grid;
export const EditRoleAssignViewModal = ({
  onClose,
  editRoleData,
  refetch,
}: {
  onClose: () => void;
  editRoleData: any;
  refetch: any;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const { id } = useParams();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<RoleFormValues>();
  const userQueryResult: any = useGetRoleById(
    `${editRoleData?.Id}&UserType=2`,
    {
      cacheTime: 0,
    },
  );
  const { isFetching } = userQueryResult;
  const { isLoading, mutate } = useEditRole();
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>(
    {},
  );
  useEffect(() => {
    if (userQueryResult?.data?.rc) {
      const initialState: { [key: string]: boolean } = {};
      userQueryResult?.data?.rc.forEach((item: any) => {
        initialState[item.Id] = item.IsChecked ?? false; // Set default to false if IsChecked is undefined
      });
      setSwitchStates(initialState);
    }
  }, [userQueryResult?.data]);
  const getInitialValues = {
    [RoleFormField.RoleName]: editRoleData.RoleName,
  };
  const handleUpdate = (values: any) => {
    const payload: any = {
      RoleName: values.RoleName, // From form input
      RoleId: editRoleData.Id,
      UserType: 2,
      CompanyId: id,
      PermissionIds: Object.keys(switchStates)
        .filter((id1) => switchStates[id1]) // Filter only checked permissions
        .map((id2) => ({
          id: Number(id2), // Convert id to number (if needed)
        })),
    };

    mutate(payload, {
      onSuccess: () => {
        onClose(); // Call the function when the mutation succeeds
        refetch(); // Refetch data after success
      },
    });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={'Edit Role'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={'Save Role'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
        }}
      >
        <Form<RoleFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <RoleInfoFormItems
            data={userQueryResult?.data}
            isFetching={isFetching}
            switchStates={switchStates}
            setSwitchStates={setSwitchStates}
          />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
