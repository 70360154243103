import { CaretRightOutlined } from '@ant-design/icons';
import {
  Collapse,
  CollapseProps,
  Form,
  Input,
  Modal,
  Select,
  Switch,
} from 'antd';
import { useGetClientsServiceList } from 'features/assigned-clients/use-cases/get-clientsService';
import { useUpdateUser } from 'features/assigned-clients/use-cases/update-user';
import { useUpdateUserEmailAccess } from 'features/assigned-clients/use-cases/update-User-EmailAccess';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useGetRoleList } from 'features/users/use-cases/get-role';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
export const EditManageUser = ({ onClose, userData }: any) => {
  const formName = 'edit-user-form';
  const [form] = Form.useForm();
  const { id } = useParams();
  const { mutate, isLoading } = useUpdateUser();
  const { mutate: EmailAccess, isLoading: emailLoading } =
    useUpdateUserEmailAccess();
  const [categoryChecks, setCategoryChecks] = useState<any>({});
  const [subCategoryChecks, setSubCategoryChecks] = useState<any>({});
  const [classCategoryChecks, setClassCategoryChecks] = useState<any>({});
  const { data } = useGetRoleList({ UserType: 2, CompanyId: id } as any);
  const parmas = {
    CompanyId: id,
    ClientId: userData?.Id,
  };
  const getUsersListQueryResponse = useGetClientsServiceList(parmas, {
    retry: false, // Disable retry if the query fails
  });
  const handleCategorySwitchChange = (
    checked: boolean,
    categoryId: string,
    subCategories: any[],
  ) => {
    setCategoryChecks((prev: any) => ({ ...prev, [categoryId]: checked }));

    const updatedSubCategoryChecks = { ...subCategoryChecks };
    const updatedClassCategoryChecks = { ...classCategoryChecks };

    subCategories.forEach((subCategory) => {
      updatedSubCategoryChecks[subCategory.Id] = checked;
      subCategory.ClassCategoryList.forEach((classCategory: any) => {
        updatedClassCategoryChecks[classCategory.Id] = checked;
      });
    });

    setSubCategoryChecks(updatedSubCategoryChecks);
    setClassCategoryChecks(updatedClassCategoryChecks);
  };

  const handleSubCategorySwitchChange = (
    checked: boolean,
    subCategoryId: string,
    classCategories: any[],
  ) => {
    setSubCategoryChecks((prev: any) => ({
      ...prev,
      [subCategoryId]: checked,
    }));

    const updatedClassCategoryChecks = { ...classCategoryChecks };
    classCategories.forEach((classCategory: any) => {
      updatedClassCategoryChecks[classCategory.Id] = checked;
    });

    setClassCategoryChecks(updatedClassCategoryChecks);
  };
  const getItems: (panelStyle1: CSSProperties) => CollapseProps['items'] = (
    panelStyle1,
  ) =>
    getUsersListQueryResponse?.data?.data?.rc.map((category: any) => ({
      key: String(category.Id),
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>{category.CategoryName}</span>
          <Switch
            checked={categoryChecks[category.Id] || false}
            onChange={(checked) =>
              handleCategorySwitchChange(
                checked,
                category.Id,
                category.SubCategoryList,
              )
            }
          />
        </div>
      ),
      children: (
        <div>
          {category.SubCategoryList.map((subCategory: any) => (
            <div
              key={subCategory.Id}
              style={{ marginLeft: '25px', marginBottom: '10px' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span className='fw-500'>{subCategory.SubCategory}</span>
                <Switch
                  checked={subCategoryChecks[subCategory.Id] || false}
                  onChange={(checked) =>
                    handleSubCategorySwitchChange(
                      checked,
                      subCategory.Id,
                      subCategory.ClassCategoryList,
                    )
                  }
                />
              </div>

              {/* Class categories */}
              {subCategory.ClassCategoryList.length > 0 && (
                <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                  {subCategory.ClassCategoryList.map((classCategory: any) => (
                    <div
                      key={classCategory.Id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <span>{classCategory.ClassCategory}</span>
                      <Switch
                        checked={classCategoryChecks[classCategory.Id] || false}
                        onChange={(checked) =>
                          setClassCategoryChecks((prev: any) => ({
                            ...prev,
                            [classCategory.Id]: checked,
                          }))
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ),
      style: panelStyle1,
    }));
  const handlePayloadSet = () => {
    const payload: { ServiceId: string; categoryName: string }[] = []; // Initialize payload array
    getUsersListQueryResponse?.data?.data?.rc.forEach((category: any) => {
      // Check if the main category switch is checked
      if (categoryChecks[category.Id]) {
        // Include the main category if its switch is checked
        payload.push({ ServiceId: category.Id } as any);

        category.SubCategoryList?.forEach((subCategory: any) => {
          // Include subcategory only if its switch is checked
          if (subCategoryChecks[subCategory.Id]) {
            payload.push({ ServiceId: subCategory.Id } as any);
          }

          subCategory.ClassCategoryList?.forEach((classCategory: any) => {
            // Include class category only if its switch is checked
            if (classCategoryChecks[classCategory.Id]) {
              payload.push({ ServiceId: classCategory.Id } as any);
            }
          });
        });
      } else {
        // If the main category switch is unchecked, skip it along with its subcategories and class categories
        category.SubCategoryList?.forEach((subCategory: any) => {
          if (subCategoryChecks[subCategory.Id]) {
            payload.push({ ServiceId: subCategory.Id } as any);
          }

          subCategory.ClassCategoryList?.forEach((classCategory: any) => {
            if (classCategoryChecks[classCategory.Id]) {
              payload.push({ ServiceId: classCategory.Id } as any);
            }
          });
        });
      }
    });

    // Set the payload for further processing
    return payload;
  };
  const handleSubmit = (formData: any) => {
    const payload = handlePayloadSet();
    const data1 = {
      CompanyId: id,
      UserId: userData?.Id,
      RoleId: formData.RoleId,
      SendMailAccess: formData?.SendMailAccess,
      ServiceListTemp: JSON.stringify(payload),
    };
    const emailAccessData = {
      CompanyId: id,
      SendMailAccess: formData?.SendMailAccess,
      UserId: userData?.Id,
    };
    if (userData?.IsPrimary == true) {
      EmailAccess(emailAccessData, {
        onSuccess: () => {
          onClose();
        },
      });
    } else {
      mutate(data1, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  };
  useEffect(() => {
    if (userData) {
      // Set the form fields with the user data
      form.setFieldsValue({
        RoleId: userData?.RoleId,
        Name: userData?.Name,
        Email: userData?.Email,
        SendMailAccess: userData?.SendMailAccess,
      });
    }
  }, [userData, form]);
  useEffect(() => {
    if (getUsersListQueryResponse?.data?.data) {
      const initialCategoryChecks: Record<string, boolean> = {};
      const initialSubCategoryChecks: Record<string, boolean> = {};
      const initialClassCategoryChecks: Record<string, boolean> = {};

      getUsersListQueryResponse?.data?.data.rc.forEach((category: any) => {
        initialCategoryChecks[category.Id] = category.IsChecked;

        category.SubCategoryList.forEach((subCategory: any) => {
          initialSubCategoryChecks[subCategory.Id] = subCategory.IsChecked;

          subCategory.ClassCategoryList.forEach((classCategory: any) => {
            initialClassCategoryChecks[classCategory.Id] =
              classCategory.IsChecked;
          });
        });
      });

      setCategoryChecks(initialCategoryChecks);
      setSubCategoryChecks(initialSubCategoryChecks);
      setClassCategoryChecks(initialClassCategoryChecks);
    }
  }, [getUsersListQueryResponse?.data?.data]);

  return (
    <StyledUserModalWrapper className='rightmodal'>
      <Modal
        open
        centered
        title='Edit User'
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText='Update User'
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ form: formName, loading: isLoading || emailLoading }}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          disabled={isLoading || emailLoading}
        >
          <Form.Item
            name='Name'
            label='User name'
            rules={[
              {
                required: true,
                message: 'User name is required.',
              },
            ]}
          >
            <Input disabled placeholder='Enter User name' />
          </Form.Item>
          <Form.Item
            name='Email'
            label='Email'
            rules={[
              {
                required: true,
                message: 'Email is required.',
              },
            ]}
          >
            <Input disabled placeholder='Enter Email' />
          </Form.Item>
          <Form.Item
            label='Role Name'
            name='RoleId'
            rules={[{ required: true, message: 'Please select a role!' }]}
          >
            <Select
              options={data?.rc?.map((item: any) => ({
                value: item.Id,
                label: item.RoleName,
              }))}
              disabled
              placeholder='Please Select Role'
            />
          </Form.Item>
          <Form.Item
            label='Send Mail'
            name='SendMailAccess'
            valuePropName='checked'
          >
            <Switch />
          </Form.Item>
          {!userData?.IsPrimary && (
            <Collapse
              bordered={false}
              defaultActiveKey={[1]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              // style={{ background: token.colorBgContainer }}
              items={getItems({})}
            />
          )}
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
