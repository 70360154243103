import { Button, Col, Form, Grid, Row } from 'antd';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { PasswordFormItems } from 'features/users/components/modals/password-form-items';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordField, PasswordFormValues } from './types';

const { useBreakpoint } = Grid;

type PasswordFormProps = {
  handleSubmit: (formValues: PasswordFormValues, onSuccess: () => void) => void;
  isLoading: boolean;
  setIsPasswordFormDirty: Dispatch<SetStateAction<boolean>>;
};

export const PasswordForm = ({
  handleSubmit,
  isLoading,
  setIsPasswordFormDirty,
}: PasswordFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const getInitialValues = {
    [PasswordField.Password]: '',
  };

  const handleOnFinish = (formData: PasswordFormValues) => {
    const onSuccess = () => form.resetFields();
    handleSubmit(formData, onSuccess);
    setIsPasswordFormDirty(false);
  };

  return (
    <>
      <StyledContentBackground className='card h-full'>
        <Form
          id='password-form'
          name='password-form'
          form={form}
          layout={'vertical'}
          initialValues={getInitialValues}
          onFinish={handleOnFinish}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          onFieldsChange={() => setIsPasswordFormDirty(true)}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Col span={screens.lg ? 7 : 'auto'} offset={screens.lg ? 7 : 0}>
            <PasswordFormItems title={t('accountSettings.changePassword')} />

            <Row justify='end'>
              <Button type='primary' disabled={isLoading} htmlType='submit'>
                Change Password
              </Button>
            </Row>
          </Col>
        </Form>
      </StyledContentBackground>
    </>
  );
};
