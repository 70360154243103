import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Grid,
  Image,
  Input,
  message,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { Modal } from 'components';
import { useCreateUploadLegalDocumentsAssign } from 'features/assigned-clients/use-cases/create-upload-legal';
import { useUploadImage } from 'features/assigned-clients/use-cases/upload-image';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { convertFileSize, validateFileBeforeUpload } from 'routes/utils';

const { useBreakpoint } = Grid;

export const UploadDocumentsLegalItemModal = ({
  onClose,
  refetch,
}: {
  onClose: () => void;
  refetch: () => void;
}) => {
  const formName = 'add-category-form';
  const { id } = useParams();
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false); // Loading state for image upload
  const [blobUrl, setBlobUrl] = useState<string>(''); // Store the uploaded blob URL
  const [filenameUrl, setFileNameUrl] = useState('');
  const [form] = Form.useForm();
  const { mutate, isLoading } = useCreateUploadLegalDocumentsAssign();
  const { isLoading: imageLoading, mutate: uploadImage } = useUploadImage();
  const handleImageUpload = (file: UploadFile) => {
    const formData = new FormData();
    formData.append('file', file as any);
    setIsImageUploading(true); // Set uploading state to true
    uploadImage(
      { id: id as string, formData }, // Pass id and file as payload
      {
        onSuccess: (response: any) => {
          setBlobUrl(response.blobUrl); // Save blob URL
          setFileNameUrl(response.fileName);
          message.success('File uploaded successfully');
          setIsImageUploading(false); // Reset uploading state
        },
        onError: () => {
          message.error('File upload failed');
          setIsImageUploading(false); // Reset uploading state
        },
      },
    );
  };
  const handleUpdate = (formValue: any) => {
    // if (!fileList.length) {
    //   form.setFields([
    //     { name: 'document', errors: ['Please upload a document'] },
    //   ]);

    //   return;
    // }
    if (!blobUrl) {
      return message.error('Please upload an file before saving');
    }
    const formData = new FormData();
    const file: any = fileList[0]?.originFileObj;
    formData.append('CompanyId', id as any);
    formData.append('documentName', formValue?.documentName);
    formData.append('file', file);
    formData.append('DocumentTypeId', 6 as any);
    formData.append('Status', 2 as any);

    formData.append('file', blobUrl);
    const uploadedFile = fileList[0]?.originFileObj; // Assuming fileList contains the uploaded file
    if (uploadedFile) {
      // Get the filename and filesize from the file object
      const fileSize = uploadedFile.size;
      formData.append('FileName', filenameUrl);
      formData.append('FileSize', convertFileSize(fileSize)); // FileSize is typically a number, so convert it to string
    }
    mutate(formData, {
      onSuccess: () => {
        refetch();
        onClose();
      },
    });
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const beforeUpload = (file: any) => {
    // Validate file before upload
    const isValid = validateFileBeforeUpload(file);

    if (isValid) {
      handleImageUpload(file); // Call handleImageUpload only if valid
    }

    return isValid; // If false, upload will be prevented
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='mbl_modal_center'
        open
        centered
        title={<span className='font-semibold'>Upload Documents</span>}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText='Save'
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
          loading: isLoading,
          className: 'btn cstmfull',
          disabled: imageLoading || isImageUploading,
        }}
      >
        <Fragment>
          <Form
            className=''
            id={formName}
            name={formName}
            form={form}
            layout='vertical'
            onFinish={handleUpdate}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
            disabled={isLoading}
          >
            <div className='d-block w-full'>
              <Form.Item
                className=''
                name='documentName'
                label='Document Name'
                rules={[
                  {
                    required: true,
                    message: 'Please enter the document name',
                  },
                ]}
              >
                <Input placeholder='Enter document name' />
              </Form.Item>

              <Form.Item label={'Upload Documents'} name={'file'}>
                <div style={{ width: '100%' }}>
                  <Upload
                    action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
                    listType='picture-card'
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    multiple={true}
                    iconRender={false as any}
                    maxCount={5} // Optional: Set a limit for the number of files
                    style={{ width: '100%' }}
                    accept='.jpg,.jpeg,.png,.webp,.csv,.xlsx,.xls,.pdf' // Restrict file types
                    beforeUpload={beforeUpload}
                  >
                    {fileList?.length >= 1 ? null : (
                      <div className='upldbtn'>{uploadButton}</div>
                    )}
                  </Upload>
                  {isImageUploading && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                      }}
                    >
                      <Spin size='large' />
                    </div>
                  )}
                </div>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Fragment>
      </Modal>
    </StyledUserModalWrapper>
  );
};
