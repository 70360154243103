import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  message,
  notification,
  Row,
} from 'antd';
import { Modal } from 'components';
import { StyledCard, StyledRow } from 'features/auth/routes/login.styles';
import { mailformValue } from 'features/new-clients/components/clients-form/type';
import { useVerfiyEmailChange } from 'features/users/use-cases/update-verfiy';
import { useOtpVerfiedUser } from 'features/users/use-cases/verfied-otp-user';
import { useEffect, useState } from 'react';

const { useBreakpoint } = Grid;

export const EmailVerifyOtpViewModal = ({
  onClose,
  user,
}: {
  onClose: () => void;
  user: any;
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm<mailformValue>();
  const { isLoading, mutate } = useOtpVerfiedUser();
  const [otp, setOtp] = useState(Array(6).fill('')); // Initialize OTP with 6 empty fields
  const userIdValue = localStorage.getItem('userId');
  const { mutate: verfiyEmailMutate, isLoading: EmailVerfiy } =
    useVerfiyEmailChange();
  const [showOtpCol, setShowOtpCol] = useState(false);
  const [emailData, setEmailData] = useState<any>('');
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        Email: user?.Email || '',
      } as any);
    }
  }, [user, form]);

  const handleChange = (value: string, index: number) => {
    if (value.length > 1) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }
  };

  const handleUpdateVerfiy = (formValue: any) => {
    const formData = {
      UserId: userIdValue,
      Email: formValue.Email,
    };
    verfiyEmailMutate(formData, {
      onSuccess: (data: any) => {
        if (data?.rs === 1) {
          message.success('OTP sent to your email');
          setShowOtpCol(true);
        } else {
          message.error('Email is already in use');
        }
      },
    });
    setEmailData(formValue);
  };
  const handleResendOtp = () => {
    const formData = {
      UserId: userIdValue,
      Email: emailData.Email,
    };
    verfiyEmailMutate(formData, {
      onSuccess: (data: any) => {
        if (data?.rs == 1) {
          message.success('Resend Successfully');
        }
      },
    });
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      if (newOtp[index] === '') {
        if (index > 0) {
          const prevInput = document.getElementById(`otp-${index - 1}`);
          prevInput?.focus();
        }
      } else {
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData('Text').replace(/\D/g, '');
    if (pastedText.length > 6) return;

    const newOtp = otp.map((_, index) => pastedText[index] || '');
    setOtp(newOtp);

    const lastFilledIndex = newOtp.findIndex((value) => value === '');
    if (lastFilledIndex !== -1) {
      const nextInput = document.getElementById(`otp-${lastFilledIndex}`);
      nextInput?.focus();
    } else {
      const lastInput = document.getElementById(`otp-5`);
      lastInput?.focus();
    }
    e.preventDefault();
  };

  const handleVerify = () => {
    const otpCode = otp.join('');
    if (otpCode.length !== 6) {
      notification.error({
        message: 'Invalid OTP',
        description: 'Please enter a valid 6-digit OTP.',
      });

      return;
    }

    mutate(
      {
        VerificationCode: otpCode,
        Email: emailData?.Email,
        UserId: userIdValue,
      },
      {
        onSuccess: (data: any) => {
          if (data?.rs === 1) {
            notification.success({
              message: 'OTP Verified',
              description: 'Your email has been successfully verified.',
            });
            onClose();
          } else {
            message.error(data?.rm);
          }
        },
        onError: (err: any) => {
          notification.error({
            message: 'Verification Failed',
            description: err.message || 'Invalid OTP. Please try again.',
          });
        },
      },
    );
  };

  return (
    <Modal
      className='mbl_modal_center'
      open
      centered
      title={showOtpCol ? 'Verify OTP' : 'Verify Email'}
      onCancel={onClose}
      getContainer={false}
      width={screens.lg ? '520px' : '100%'}
      footer={null}
    >
      <Form form={form} onFinish={handleUpdateVerfiy}>
        <Col>
          {!showOtpCol && (
            <>
              <Form.Item
                label='Email'
                name='Email'
                rules={[
                  { required: true, message: 'Please enter your email!' },
                ]}
              >
                <Input placeholder='Enter your email' />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ marginLeft: '50px' }}
                  type='primary'
                  htmlType='submit'
                  loading={EmailVerfiy}
                >
                  Verify Email
                </Button>
              </Form.Item>
            </>
          )}
        </Col>
      </Form>
      {showOtpCol && (
        <Col className='flex flex-col justify-center p-5'>
          <StyledCard className='p-0'>
            <StyledRow justify='center'>
              <Col style={{ textAlign: 'center' }}>
                <label className='mt-0 mb-3 text-center text-2xl font-bold text-blue-950'>
                  Verify OTP
                </label>
                <p className='text-gray-500'>
                  A verification code has been sent to
                </p>
                <p className='text-blue-600 font-semibold'>
                  {emailData?.Email || 'your email'}
                </p>
              </Col>
            </StyledRow>
            <StyledRow justify='center'>
              <Col xs={24}>
                <Row gutter={16} className='mt-7' justify='center'>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <Col key={index}>
                      <Input
                        id={`otp-${index}`}
                        value={otp[index]}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        maxLength={1}
                        style={{
                          width: '45px',
                          textAlign: 'center',
                        }}
                        size='large'
                        autoComplete='off'
                        type='tel'
                        inputMode='numeric'
                      />
                    </Col>
                  ))}
                </Row>
                <div className='mt-14 flex justify-center'>
                  <Button
                    className='btn'
                    type='primary'
                    loading={isLoading}
                    onClick={handleVerify}
                  >
                    Verify
                  </Button>
                </div>
                <div className='mt-14 flex justify-center'>
                  <Button
                    className='btn'
                    type='primary'
                    loading={EmailVerfiy}
                    onClick={handleResendOtp}
                  >
                    Resend
                  </Button>
                </div>
              </Col>
            </StyledRow>
          </StyledCard>
        </Col>
      )}
    </Modal>
  );
};
